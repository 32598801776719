import React, { useEffect, useState } from 'react';
import { TableRow, Modal, Box, Typography, Button, TableContainer, Table, TableHead, TableBody, IconButton } from '@mui/material';
import { TableCellBody, TableCellHead } from '../table/tableCell';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useParams } from 'react-router-dom';
import { addId, removeId } from '../../redux/reducers/taskIdSlice';
import AllocationModal from './allocateModal';
import ConfirmationDialog from './confirmationModal';
import { createAllocation, getUpload, setCreateAllocationStatus } from '../../redux/slices/upload';
import CloseIcon from '@mui/icons-material/Close'
import SnackbarNotification from '../snackbar/snackbarNotification';
import { STATUS } from '../../utils/status';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteModal from '../modal/deleteModal';
import EditIcon from '@mui/icons-material/Edit';

interface TableRowItemProps {
  row: any;
  onEditClick: (row: any) => void;
  onDeleteClick: (userId: string) => void;
  handleClick: (row: any) => void;
}


const TableRowItem: React.FC<TableRowItemProps> = ({ row, onEditClick, onDeleteClick, handleClick }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedTaskerId, setSelectedTaskerId] = useState<string | null>(null);
  const [selectedTaskerName, setSelectedTaskerName] = useState<string | null>(null);
  const deadline = useSelector((state: RootState) => state.deadline.deadline)
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [allocationType, setAllocationType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const createStatus = useSelector((state: RootState) => state.allocation.createStatus);
  // console.log({ createStatus })
  const updateStatus = useSelector((state: RootState) => state.allocation.updateStatus)
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allocationIdToUnallocate, setAllocationIdToUnallocate] = useState<string | null>(null);
  const [checkedRow, setCheckedRow] = useState(false);
  const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const association_type_company = association_type && association_type === 'COMPANY' ? true : false;
  const association_type_vendor = association_type && association_type === 'VENDOR' ? true : false;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleAvatarClick = (allocation: any) => {
    setAllocationType('TASK')
    setSelectedAllocation(allocation);
    setIsModalOpen(true);
  };

  const handleAvatarReviewClick = (allocation: any) => {
    setAllocationType('REVIEW')
    setSelectedAllocation(allocation);
    setIsModalOpen(true);
  };

  const handleCloseAvatarModal = () => {
    setIsModalOpen(false);
    setSelectedAllocation(null);
  };


  const handleOpenTaskModal = () => {
    setAllocationType('TASK')
    setOpenModal(true);
  };

  const handleOpenReviewModal = () => {
    setAllocationType('REVIEW')
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedEmail('')
    setOpenModal(false);
  };

  const handleCancelConfirmation = () => {
    setOpenConfirmationDialog(false);
    setOpenModal(true)
  };

  const handleEmailClick = ({ id, email, name }: { id: string; email: string, name: string }) => {
    setSelectedEmail(email);
    setSelectedTaskerId(id);
    setSelectedTaskerName(name);
  };

  // useEffect(() => {
  //   if (selectedEmail && selectedTaskerId) {
  //     console.log({ selectedTaskerId });
  //     console.log({ selectedEmail });
  //   }
  // }, [selectedEmail, selectedTaskerId]);


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const parsedValue = JSON.parse(value);



    if (checked) {
      setCheckedRow(true)
      dispatch(addId(parsedValue));
    } else {
      setCheckedRow(false)
      dispatch(removeId(parsedValue.id));
    }
  };

  const handleAllocateClick = () => {
    setOpenConfirmationDialog(true)
    setOpenModal(false)
  };

  const handleConfirmAllocation = () => {
    const payload = {
      project_id: id,
      task_ids: [row.id],
      expert_ids: [selectedTaskerId],
      type: allocationType,
      deadline: deadline,
      action: "assign"
    }
    dispatch(createAllocation({
      payload,
      extraData: {
        'expertDetails': [selectedEmail, selectedTaskerId, selectedTaskerName]
      }
    }))
    handleCloseModal();
    setOpenConfirmationDialog(false)
  }

  const handleUnallocate = (AllocationId: string) => {
    const payload = {
      project_id: id,
      task_ids: [row.id],
      expert_ids: [AllocationId],
      type: allocationType,
      action: "unassign"
    }
    dispatch(createAllocation({
      payload,
      extraData: {
        'expertDetails': [AllocationId]
      }
    }))
    handleCloseAvatarModal();
  }

  useEffect(() => {
    if (createStatus === STATUS.SUCESS) {
      handleSnackbar('Success', 'success');
      setShowDeleteModal(false);
      // dispatch(getUpload({ project_id: id || '' }))
    }

    if (createStatus === STATUS.FAILED) {
      handleSnackbar('Error Occurred, Please try again!', 'error');
    }

    return () => {
      if (createStatus === STATUS.SUCESS || createStatus === STATUS.FAILED) {
        dispatch(setCreateAllocationStatus(STATUS.NOT_STARTED));
      }
    };

  }, [createStatus]);

  const truncateName = (name: string): string => {
    const maxLength = 30;
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  };

  const handleArrowClick = () => {
    handleClick(row);
  };

  const cancelDelete = () => {
    setAllocationIdToUnallocate(null);
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    if (allocationIdToUnallocate) {
      handleUnallocate(allocationIdToUnallocate);
    }
    setAllocationIdToUnallocate(null);
  };

  const handleOpenDeleteModal = (allocationId: string) => {
    setAllocationIdToUnallocate(allocationId);
    setShowDeleteModal(true);
  };

  const headers = ["NAME", "EMAIL", "TASK STATUS", "UNALLOCATE"];

  
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          '&:last-child td, &:last-child th': { borderBottom: 0 },
          backgroundColor: checkedRow ? '#89CFF0' : '',
        }}
      >
        <TableCellBody>
          {fetchPermissions?.completing_task_action_details?.[0] === false ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Tooltip title={row.task_name || '-'}>
                <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {truncateName(row.task_name || '-')}
                </div>
              </Tooltip>
              <div style={{ flex: 1 }}>
                {/* <KeyboardArrowRightIcon fontSize='small' sx={{ cursor: 'pointer' }} /> */}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={handleArrowClick}>
              <Tooltip title={row.task_name || '-'}>
                <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                  {truncateName(row.task_name || '-')}
                </div>
              </Tooltip>
              <div style={{ flex: 1 }}>
                <KeyboardArrowRightIcon fontSize='small' sx={{ cursor: 'pointer' }} />
              </div>
            </div>
          )}
        </TableCellBody>

        {
          ((
            association_type_vendor &&
            (
              (
                Object.keys(fetchPermissions).includes('task_allocation_single') &&
                fetchPermissions['task_allocation_single'][0] === true
              ) ||
              (
                Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
                fetchPermissions['task_allocation_bulk'][0] === true
              ) ||
              (
                Object.keys(fetchPermissions).includes('task_unallocation_single') &&
                fetchPermissions['task_unallocation_single'][0] === true
              ) ||
              (
                Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
                fetchPermissions['task_unallocation_bulk'][0] === true
              )
            )
          ) ||
            (
              association_type_company &&
              (
                Object.keys(fetchPermissions).includes('task_allocation') &&
                fetchPermissions['task_allocation'][0] == true
              )
            )
          ) ? <TableCellBody><div>{row.task_allocations[0] ? row.task_allocations[0].status : 'NOT ALLOCATED'}</div></TableCellBody> : <></>
        }

        {((
          association_type_vendor &&
          (
            (
              Object.keys(fetchPermissions).includes('review_allocation_single') &&
              fetchPermissions['review_allocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_allocation_bulk') &&
              fetchPermissions['review_allocation_bulk'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_unallocation_single') &&
              fetchPermissions['review_unallocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
              fetchPermissions['review_unallocation_bulk'][0] === true
            )
          )
        ) ||
          (
            association_type_company &&
            (
              Object.keys(fetchPermissions).includes('review_allocation') &&
              fetchPermissions['review_allocation'][0] == true
            )
          )
        ) ?
          <TableCellBody><div>{row.review_allocations[0] ? row.review_allocations[0].status : 'NOT ALLOCATED'}</div></TableCellBody> : <></>
        }

        {((
          association_type_vendor &&
          (
            (
              Object.keys(fetchPermissions).includes('task_allocation_single') &&
              fetchPermissions['task_allocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
              fetchPermissions['task_allocation_bulk'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_unallocation_single') &&
              fetchPermissions['task_unallocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
              fetchPermissions['task_unallocation_bulk'][0] === true
            )
          )
        ) ||
          (
            association_type_company &&
            (
              Object.keys(fetchPermissions).includes('task_allocation') &&
              fetchPermissions['task_allocation'][0] == true
            )
          )
        ) ?
          <TableCellBody>
            {row.task_allocations.length > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                {row.task_allocations.map((allocation: { id: string; pseudo_name: string; }) => (
                  <div key={allocation.id}>
                    {allocation.pseudo_name || '-'}
                  </div>
                ))}
              </div>
            ) : (
              <Typography>-</Typography>
            )}
          </TableCellBody> : <></>
        }

        {((
          association_type_vendor &&
          (
            (
              Object.keys(fetchPermissions).includes('task_allocation_single') &&
              fetchPermissions['task_allocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
              fetchPermissions['task_allocation_bulk'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_unallocation_single') &&
              fetchPermissions['task_unallocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
              fetchPermissions['task_unallocation_bulk'][0] === true
            )
          )
        ) ||
          (
            association_type_company &&
            (
              Object.keys(fetchPermissions).includes('task_allocation') &&
              fetchPermissions['task_allocation'][0] == true
            )
          )
        ) ? <TableCellBody>
          {
            (
              association_type_company &&
              (
                Object.keys(fetchPermissions).includes('task_allocation') &&
                fetchPermissions['task_allocation'][0] == true
              )
            ) ||
              (
                association_type_vendor &&
                (
                  Object.keys(fetchPermissions).includes('task_allocation_single') &&
                  fetchPermissions['task_allocation_single'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
                  fetchPermissions['task_allocation_bulk'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('task_unallocation_single') &&
                  fetchPermissions['task_unallocation_single'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
                  fetchPermissions['task_unallocation_bulk'][0] === true
                )
              )
              ?
              (
                row.task_allocations.length > 0 ?
                  (
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                      {row.task_allocations.map((allocation: { id: string; name: string; email: string }) => (
                        <div
                          // onClick={() => handleAvatarClick(allocation)}
                          style={{
                            display: "flex",
                            gap: "10px",
                            // cursor: 'pointer'
                          }}
                        >
                          {(allocation.name && allocation.name.trim() ? allocation.name : allocation.email)}
                          {
                            (
                              association_type_company &&
                              (
                                Object.keys(fetchPermissions).includes('task_allocation') &&
                                fetchPermissions['task_allocation'][0] == true
                              )
                            ) ||
                              (
                                association_type_vendor &&
                                (
                                  Object.keys(fetchPermissions).includes('task_unallocation_single') &&
                                  fetchPermissions['task_unallocation_single'][0] === true
                                )
                              ) ? (
                              <div className='icon-container'>
                                <EditIcon
                                  onClick={() => handleAvatarClick(allocation)}
                                  style={{ fontSize: "17px", cursor: 'pointer'}}
                                />
                              </div>
                            ) : <></>
                          }
                        </div>
                      ))}
                    </div>
                  )
                  : (
                      <span>
                        {
                          (
                            association_type_vendor &&
                            (
                              Object.keys(fetchPermissions).includes('task_allocation_single') &&
                              fetchPermissions['task_allocation_single'][0] === true
                            )
                          ) ||
                          (
                            association_type_company &&
                            (
                              Object.keys(fetchPermissions).includes('task_allocation') &&
                              fetchPermissions['task_allocation'][0] == true
                            )
                          ) ? <AddIcon sx={{ cursor: 'pointer' }} onClick={handleOpenTaskModal} /> : '-'
                        }
                      </span>
                   )
              )
              :
              "-"
          }
        </TableCellBody> : <></>}

        {((
          association_type_vendor &&
          (
            (
              Object.keys(fetchPermissions).includes('review_allocation_single') &&
              fetchPermissions['review_allocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_allocation_bulk') &&
              fetchPermissions['review_allocation_bulk'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_unallocation_single') &&
              fetchPermissions['review_unallocation_single'][0] === true
            ) ||
            (
              Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
              fetchPermissions['review_unallocation_bulk'][0] === true
            )
          )
        ) ||
          (
            association_type_company &&
            (
              Object.keys(fetchPermissions).includes('review_allocation') &&
              fetchPermissions['review_allocation'][0] == true
            )
          )
        ) ? <TableCellBody>
          {
            (
              association_type_company &&
              (
                Object.keys(fetchPermissions).includes('review_allocation') &&
                fetchPermissions['review_allocation'][0] == true
              )
            ) ||
            (
              association_type_vendor && 
              (
                (
                  Object.keys(fetchPermissions).includes('review_allocation_single') &&
                  fetchPermissions['review_allocation_single'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('review_allocation_bulk') &&
                  fetchPermissions['review_allocation_bulk'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('review_unallocation_single') &&
                  fetchPermissions['review_unallocation_single'][0] === true
                ) ||
                (
                  Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
                  fetchPermissions['review_unallocation_bulk'][0] === true
                )
              )
            ) 
            ? 
              (
                Object.keys(row).includes('review_allocations') && row.review_allocations.length > 0 ? 
                  (
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                      {row.review_allocations.map((allocation: { id: string; name: string; email: string }) => (
                        <div
                          // onClick={() => handleAvatarReviewClick(allocation)}
                          style={{ 
                            display: "flex", 
                            gap: "10px", 
                            // cursor: 'pointer' 
                          }}
                        >
                          {(allocation.name && allocation.name.trim() ? allocation.name : allocation.email)}
                          {
                            (
                              association_type_company &&
                              (
                                Object.keys(fetchPermissions).includes('review_allocation') &&
                                fetchPermissions['review_allocation'][0] == true
                              )
                            ) ||
                            (
                              association_type_vendor && 
                              (
                                (
                                  Object.keys(fetchPermissions).includes('review_unallocation_single') &&
                                  fetchPermissions['review_unallocation_single'][0] === true
                                )
                              )
                            )  ? 
                              <div className='icon-container'>
                                <EditIcon style={{ fontSize: "17px", cursor: 'pointer' }} onClick={() => handleAvatarReviewClick(allocation)} />
                              </div>
                            : <></>
                          }
                        </div>
                      ))}
                    </div>
                  ) 
                : (
                    <Tooltip
                      title={
                        row.task_allocations.length > 0
                          ? 'Add Review Allocation'
                          : 'You must first allocate a task before review allocation'
                      }
                      placement="bottom"
                      arrow
                    >
                      <span>
                        {
                          (
                            association_type_company &&
                            (
                              Object.keys(fetchPermissions).includes('review_allocation') &&
                              fetchPermissions['review_allocation'][0] == true
                            )
                          ) ||
                          (
                            association_type_vendor && 
                            (
                              (
                                Object.keys(fetchPermissions).includes('review_allocation_single') &&
                                fetchPermissions['review_allocation_single'][0] === true
                              )
                            )
                          )  
                          ? 
                            (
                              <AddIcon
                                sx={{
                                  cursor: row.task_allocations.length > 0 ? 'pointer' : 'not-allowed',
                                  opacity: row.task_allocations.length > 0 ? 1 : 0.5,
                                }}
                                onClick={row.task_allocations.length > 0 ? handleOpenReviewModal : undefined}
                              />
                            ) 
                          : '-'
                        }
                      </span>
                    </Tooltip>
                  )
              ) 
            : "-"
          }
        </TableCellBody> : <></>}

        {!association_type_vendor && <TableCellBody>
          <DeleteOutlineOutlinedIcon
            sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }}
            onClick={() => onDeleteClick(row.id)}
          />
        </TableCellBody>}

      </TableRow >

      <AllocationModal
        open={openModal}
        onClose={handleCloseModal}
        onEmailClick={handleEmailClick}
        allocationType={allocationType}
        selectedEmail={selectedEmail}
        onAllocateClick={handleAllocateClick}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCancelConfirmation}
        onConfirm={handleConfirmAllocation}
      />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />

      <Modal open={isModalOpen} onClose={handleCloseAvatarModal}>
        <>
          <Box
            sx={{
              p: 2,
              maxWidth: 950,
              maxHeight: 660,
              overflow: 'hidden',
              backgroundColor: 'white',
              borderRadius: '8px',
              margin: 'auto',
              marginTop: '2%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
                p: 2,
                borderBottom: '1px solid #E0E0E0',
              }}
            >
              <Typography
                id="modal-title"
                sx={{ color: 'black', fontWeight: '400', fontSize: '24px' }}
              >
                Unallocate
              </Typography>
            </Box>
            <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                    <TableRow >
                      {headers.map((header) => (
                        <TableCellHead key={header}>{header}</TableCellHead>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {Object.keys(row).includes('task_allocations') && row.task_allocations.length > 0 && allocationType === 'TASK' ? (
                      row.task_allocations.map((allocation: any) => (
                        <TableRow key={allocation.id}>
                          <TableCellBody>{(allocation.name !== "") ? allocation.name : 'Name Does not exist'}</TableCellBody>
                          <TableCellBody>{allocation.email}</TableCellBody>
                          <TableCellBody>{allocation.status}</TableCellBody>
                          {(association_type_vendor && fetchPermissions.task_unallocation_single?.[0]) ||
                            (association_type_company && fetchPermissions.task_allocation?.[0]) ?
                            <TableCellBody>
                              {row.review_allocations.length > 0 ? (
                                <Tooltip
                                  title="You must first unassign reviewer before unassigning the tasker."
                                  placement="top"
                                >
                                  <span>
                                    <IconButton
                                      onClick={() => handleOpenDeleteModal(allocation.id)}
                                      disabled
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              ) : (
                                <IconButton
                                  onClick={() => handleOpenDeleteModal(allocation.id)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </TableCellBody> :
                            <TableCellBody>
                              <Tooltip title="You don't have permission to unallocate"><CloseIcon /></Tooltip>
                            </TableCellBody>
                          }
                        </TableRow>
                      ))
                    ) : (
                      row.review_allocations.map((allocation: any) => (
                        <TableRow key={allocation.id}>
                          <TableCellBody>{allocation.name}</TableCellBody>
                          <TableCellBody>{allocation.email}</TableCellBody>
                          <TableCellBody>{allocation.status}</TableCellBody>
                          {(association_type_vendor && fetchPermissions.review_unallocation_single?.[0]) ||
                            (association_type_company && fetchPermissions.review_allocation?.[0]) ?
                            <TableCellBody>
                              <IconButton
                                onClick={() => handleOpenDeleteModal(allocation.id)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </TableCellBody> :
                            <TableCellBody>
                              <Tooltip title="You don't have permission to unallocate"><CloseIcon /></Tooltip>
                            </TableCellBody>
                          }
                        </TableRow>
                      ))
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                p: 2,
                borderTop: '1px solid #E0E0E0',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'white',
              }}
            >
              <Button
                sx={{
                  minWidth: '5px',
                  minHeight: '40px',
                  backgroundColor: '#F7F9FA',
                  padding: '8px 16px',
                  gap: '4px',
                  borderRadius: '8px',
                  ml: '90%',
                  '&:hover': { backgroundColor: '#E0E0E0' },
                }}
                onClick={handleCloseAvatarModal}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#10383D',
                    textTransform: 'none',
                  }}
                >
                  Cancel
                </Typography>
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
        deleteText='Unallocate'
        isButtonLoading={createStatus === STATUS.PENDING}
      />

    </>
  );
};

export default TableRowItem;
