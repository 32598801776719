import React from "react";
import { Typography } from "@mui/material";
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';

interface ParagraphShowActionProps {
  action_value?: any;
}

export default function ParagraphShowAction({ action_value }: ParagraphShowActionProps) {

    const renderers: Components = {
        code({ node, inline, className, children, ...props }: any & { inline?: boolean }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={darcula}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      };

    return (
        <>
        {action_value && action_value.map((action: any, index: number) => (
                <div key={index} style={{ display: 'flex', marginBottom: '10px', flexDirection:'column' }}>
                <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>{action.config_details.value.title}: </Typography>
                    {action.config_details.default_value ?
                        <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers} className="markdown-body">{action.config_details.default_value}</ReactMarkdown>
                        : (
                            <p>Content not available.</p>
                        )}
                </div>
        ))}
        </>
    );
}
