import { ActionReducerMapBuilder, current, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { UploadState } from '../interfaces/interface';
import { createAllocation, createBulkAllocation, createBulkUnallocation, createUpload, getUnallocatedTaskCount, getUpload, updateUpload } from '../slices/upload';

const ExtraReducers = (builder: ActionReducerMapBuilder<UploadState>) => {
  builder
  .addCase(createUpload.pending, (state) => {
    state.createStatus = STATUS.PENDING;
  })
  .addCase(createUpload.fulfilled, (state, action: PayloadAction<any>) => { 
    state.upload = action.payload; 
    state.createStatus = STATUS.SUCESS; 
  })
  .addCase(createUpload.rejected, (state, action) => { 
    state.createStatus = STATUS.FAILED;
  })
  .addCase(createAllocation.pending, (state) => {
    state.createAllocationStatus = STATUS.PENDING;
  })
  .addCase(createAllocation.fulfilled, (state, action: PayloadAction<any>) => {
    if(Object.keys(action.payload).includes('payload')) {
      console.log({action})
      if(Object.keys(action.payload.payload.payload).includes('task_ids')) {
        action.payload.payload.payload.task_ids.map((task_id:any) => {
          const taskIndex = state.upload.results.findIndex((task:any) => task.id === task_id)
          if(action.payload.payload.payload.action === 'assign') {
            if(action.payload.payload.payload.type === "REVIEW") {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                review_allocations: [ 
                  ...state.upload.results[taskIndex].review_allocations, 
                  {
                    email:action.payload?.payload?.extraData?.expertDetails?.[0],
                    status: 'NOT STARTED'
                  }
                ]
              }
            }
            else
            {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                task_allocations: [ 
                  ...state.upload.results[taskIndex].task_allocations, 
                  {
                    email:action.payload?.payload?.extraData?.expertDetails?.[0] , 
                    id:action.payload?.payload?.extraData?.expertDetails?.[1] , 
                    status: 'NOT STARTED'
                  }
                ]
              }
            }
          } else {
            if(action.payload.payload.payload.type === "REVIEW") {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                review_allocations: state.upload.results[taskIndex].review_allocations.filter((alloc:any) => alloc._id !== action.payload.payload?.extraData?.expertDetails?.[0]?.id)
              }
            }
            else
            {
              state.upload.results[taskIndex] =  {
                ...state.upload.results[taskIndex],
                task_allocations: state.upload.results[taskIndex].task_allocations.filter((alloc:any) => alloc._id !== action.payload.payload?.extraData?.expertDetails[0].id)
              }
            }
          }
          return task_id;
        })
      }
    }
    // state.upload = action.payload; 
    state.createAllocationStatus = STATUS.SUCESS; 
  })
  .addCase(createAllocation.rejected, (state, action) => { 
    state.createAllocationStatus = STATUS.FAILED;
  })
  .addCase(createBulkAllocation.pending, (state) => {
    state.createBulkAllocationStatus = STATUS.PENDING;
  })
  .addCase(createBulkAllocation.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.createBulkAllocationStatus = STATUS.SUCESS; 
  })
  .addCase(createBulkAllocation.rejected, (state, action) => { 
    state.createBulkAllocationStatus = STATUS.FAILED;
  })  
  .addCase(createBulkUnallocation.pending, (state) => {
    state.createBulkUnallocationStatus = STATUS.PENDING;
  })
  .addCase(createBulkUnallocation.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.createBulkUnallocationStatus = STATUS.SUCESS; 
  })
  .addCase(createBulkUnallocation.rejected, (state, action) => { 
    state.createBulkUnallocationStatus = STATUS.FAILED;
  })  
  .addCase(getUpload.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getUpload.fulfilled, (state, action: PayloadAction<any>) => {
    state.upload = action.payload; 
    state.getStatus = STATUS.SUCESS; 
  })
  .addCase(getUpload.rejected, (state, action) => { 
    state.getStatus = STATUS.FAILED;
  })  
  .addCase(getUnallocatedTaskCount.pending, (state) => {
    state.getTaskCountStatus = STATUS.PENDING;
  })
  .addCase(getUnallocatedTaskCount.fulfilled, (state, action: PayloadAction<any>) => {
    state.unallocatedCount = action.payload.count; 
    state.getTaskCountStatus = STATUS.SUCESS; 
  })
  .addCase(getUnallocatedTaskCount.rejected, (state, action) => { 
    state.getTaskCountStatus = STATUS.FAILED;
  })  
  .addCase(updateUpload.pending, (state) => {
    state.updateStatus = STATUS.PENDING;
  })
  .addCase(updateUpload.fulfilled, (state, action: PayloadAction<any>) => {
    const updateUpload = action.payload;
    state.upload.results = state.upload.results.map((upload: any) =>
      upload.id === updateUpload.id ? updateUpload : upload
    );
    state.updateStatus = STATUS.SUCESS;
  })
  .addCase(updateUpload.rejected, (state, action) => {
    state.updateStatus = STATUS.FAILED;
  })  
};

export default ExtraReducers;
