import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, SelectChangeEvent, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Menu, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { createMultipleExpert, getExpert, setCreateExpertsStatus, setCreateMultipleExpertsStatus, setUpdateExpertsStatus, updateExpert } from '../../redux/slices/expertSlice/addExpertSlice';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../button/button';
import { TableCellHead } from '../table/tableCell';
import DeleteModal from '../modal/deleteModal';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SnackbarNotification from '../snackbar/snackbarNotification';
import DisableModal from './disableModal';
import PaginationEntries from '../pagination/paginationEntries';
import Pagination from './expertPagination';
import { SearchBar } from '../inputField/textfield';
import { useStyles } from '../../assets/styles/listTableStyle';
import TableRowItem from './expertTableRowItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Error from '../error/error';
import Groups2Icon from '@mui/icons-material/Groups2';
import debounce from 'lodash.debounce';
import { getUser } from '../../redux/slices/userSlice/addUserSlice';

const ExpertListTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [inviteEmails, setInviteEmails] = useState("");
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [expertToDisable, setExpertToDisable] = useState<any>(null);
  const [pagesSearchByInput, setPagesSearchByInput] = useState(0)

  const experts = useSelector((state: RootState) => state.experts.experts);
  const getStatus = useSelector((state: RootState) => state.experts.getStatus);
  const createStatus = useSelector((state: RootState) => state.experts.createStatus);
console.log({experts})
  const createMultipleStatus = useSelector(
    (state: RootState) => state.experts.createMultipleStatus
  );
  const expertTableAccess = useSelector(
    (state: RootState) =>
      state.company.company.data.modules.experts.table.access
  );
  const searchAccess = useSelector(
    (state: RootState) =>
      state.company.company.data.modules.experts.table.search.access
  );
  const addAccess = useSelector(
    (state: RootState) => state.company?.company?.data?.actions?.create
  );
  const tableFields = useSelector((state: RootState) => {
    const tableFields =
      state.company?.company?.data?.modules?.experts?.table?.table_fields;
    return tableFields || [];
  });
  const updateStatus = useSelector((state: RootState) => state.experts.updateStatus);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (
    message: string,
    severity: "success" | "error" | "info" | "warning"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      dispatch(updateExpert({ id: userIdToDelete, is_deleted: true }));
      dispatch(
        getExpert({})
      );
      handleSnackbar('Expert Deleted Successfully.', 'success');
    } catch (error) {
      handleSnackbar('Error occured please try again.', 'success');
    }
  };

  useEffect(() => {
    dispatch(
      getExpert({
        search: searchTerm,
        page: currentPage,
        pageSize: itemsPerPage,
      })
    );
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  const handleAddClick = () => {
    navigate("/addexpert");
  };

  const handleEditClick = (row: any) => {
    navigate(`/addexpert/${row.id}`, {
      state: { expertDetails: row, id: row.id },
    });
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (value:number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    dispatch(getExpert({ search: searchTerm, page: 1, pageSize: value as number })); 
  };
  const handleOnChange = (value:number) => {
    setItemsPerPage(value)
  }
  
  const capitalizeAll = (str: string) => {
    return str
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.toUpperCase())
      .join(" ");
  };

  const headers = tableFields.map(capitalizeAll);

  const handleInviteClick = () => {
    setInviteModalOpen(true);
  };

  const handleInviteEmailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInviteEmails(event.target.value);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    const emailsWithCommas = pastedText.replace(/\s+/g, ",");
    setInviteEmails(
      (prevEmails) => `${prevEmails}${prevEmails ? "," : ""}${emailsWithCommas}`
    );
  };

  const validateEmails = (emails: string) => {
    const emailArray = emails.split(',').map(email => email.trim());
    const validEmailRegex = /^(?!.*\.\.)(?!.*\.$)(?!^\.)[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const invalidEmails = emailArray.filter(
      (email) => !validEmailRegex.test(email)
    );
    if (invalidEmails.length > 0) {
      return `Invalid emails ${invalidEmails.join(", ")}`;
    }

    const duplicateEmails = emailArray.filter(
      (email, index) => emailArray.indexOf(email) !== index
    );
    if (duplicateEmails.length > 0) {
      return `Duplicate emails: ${duplicateEmails.join(", ")}`;
    }

    return null;
  };

  const handleAddAllClick = () => {
    const errorMessage = validateEmails(inviteEmails);
    if (errorMessage) {
      handleSnackbar(errorMessage, "error");
      return;
    }
    const payload = { emails: inviteEmails };
    dispatch(createMultipleExpert(payload))
      .then(() => {
        setInviteEmails("");
        setInviteModalOpen(false);
        handleSnackbar("Invitations sent successfully", "success");
      })
      .catch(() => {
        handleSnackbar("Failed to send invitations.", "error");
      });
  };

  useEffect(() => {
    if (createMultipleStatus === STATUS.SUCESS) {
      handleSnackbar("Invitations sent successfully", "success");
      setTimeout(() => {
        navigate("/experts");
      }, 1000);
      dispatch(setCreateMultipleExpertsStatus(STATUS.NOT_STARTED));
      dispatch(getExpert({}));
    }

    if (createMultipleStatus === STATUS.FAILED) {
      handleSnackbar("Failed to send invitations.", "error");
      setTimeout(() => {
        handleSnackbar("expert with this email already exists", "warning");
      }, 7000);

      
      dispatch(setCreateMultipleExpertsStatus(STATUS.NOT_STARTED));
    }
  }, [createMultipleStatus]);

  const handleDisableCancel = () => {
    setShowDisableModal(false);
    setExpertToDisable(null);
  };

  const handleDisableConfirm = async () => {
    // setShowDisableModal(false);
    if (expertToDisable) {
      const updatedStatus = !expertToDisable.is_blocked;
      try {
        dispatch(
          updateExpert({ id: expertToDisable.id, is_blocked: updatedStatus })
        );
        dispatch(getExpert({}));
      } catch (error) {
        handleSnackbar("Failed to disable user", "error");
      }
    }
    setExpertToDisable(null);
  };

  const handleToggleDisable = (row: any) => {
    setExpertToDisable(row);
    setShowDisableModal(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(updateStatus == STATUS.SUCESS){
      setShowDeleteModal(false);
      dispatch(setUpdateExpertsStatus(STATUS.NOT_STARTED))
    }
  }, [updateStatus])
  
  useEffect(() => {
    if(updateStatus == STATUS.SUCESS){
      setShowDisableModal(false)
      dispatch(setUpdateExpertsStatus(STATUS.NOT_STARTED))
    }
  }, [updateStatus])

  const totalPages = Math.ceil((experts.count || 0) / itemsPerPage);
  return (
    <>

      {expertTableAccess && (
        <>
          <div className={classes.container}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ marginLeft: "0px" }}>{searchAccess && (<SearchBar onSearchChange={debouncedResults} />)}</div>
            </div>

            <div className={classes.actionsContainer}>
              <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
              {addAccess && (
                <>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleInviteClick}
                    sx={{
                      width: 'auto',
                      height: '40px',
                      marginTop: '10px',
                      borderRadius: '8px 0 0 8px',
                      padding: '8px, 16px, 8px, 16px',
                      backgroundColor: '#3D85C6',
                      textTransform: 'none',
                      marginLeft: '10px',
                      '&:hover': {
                        backgroundColor: '#3D85C6',
                      },
                    }}>
                    <Groups2Icon fontSize='small' sx={{ marginRight: '10px' }} /> Add Bulk Expert</Button>
                  <Divider orientation="vertical" flexItem sx={{ height: '1px' }} />
                  <ArrowDropDownIcon fontSize='small' onClick={handleMenuClick} sx={{
                    width: '30px',
                    height: '40px',
                    marginTop: '10px',
                    color: 'white',
                    borderRadius: '0 8px 8px 0',
                    padding: '8px, 16px, 8px, 16px',
                    backgroundColor: '#3D85C6',
                    '&:hover': {
                      backgroundColor: '#3D85C6',
                    },
                  }} />
                  <Menu
                    id="invite-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleAddClick}><PersonAddAlt1Icon fontSize='small' sx={{ marginRight: '5px' }} />Add Expert</MenuItem>
                  </Menu>
                </>)}
            </div>
          </div>
          <Dialog
            open={inviteModalOpen}
            onClose={() => setInviteModalOpen(false)}
            maxWidth={false}
            sx={{
              '& .MuiDialog-paper': {
                width: '400px',
                height: '300px',
                padding: '20px',
              }
            }}
          >
            <DialogTitle><Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '18px', lineHeight: '24px', textAlign: 'start', color: '#10383D', textTransform: 'none' }}>Invite Experts</Typography></DialogTitle>
            <DialogContent>
              <Typography sx={{ marginBottom: '20px', fontFamily: 'Montserrat', fontWeight: '400', fontSize: '12px', lineHeight: '24px', textAlign: 'start', color: '#10383D', textTransform: 'none' }}>Enter emails separated by commas that you want to invite for experts</Typography>
              <TextField
                id="invite-emails"
                variant="outlined"
                placeholder="Enter emails separated by commas"
                value={inviteEmails}
                onChange={handleInviteEmailsChange}
                onPaste={handlePaste}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button sx={{ textTransform: 'none', width: 'auto', height: '40px', borderRadius: '8px', color: '#000000', padding: '8px, 16px, 8px, 16px', backgroundColor: '#F7F9FA', '&:hover': { backgroundColor: '#F7F9FA' }, }} variant="contained" onClick={() => setInviteModalOpen(false)}>Cancel</Button>
              <Button sx={{ marginRight: '20px', textTransform: 'none', width: 'auto', height: '40px', borderRadius: '8px', padding: '8px, 16px, 8px, 16px', backgroundColor: '#3D85C6', '&:hover': { backgroundColor: '#3D85C6' }, }} variant="contained" onClick={handleAddAllClick}>Invite All</Button>
            </DialogActions>
          </Dialog>


          <TableContainer style={{ minHeight: '75px' }}>
            {getStatus == STATUS.PENDING && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
              </div>
            )}
            {getStatus == STATUS.SUCESS && (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
                  <TableRow>
                    {headers.map((header) => (
                      <TableCellHead key={header}>{header}</TableCellHead>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getStatus === STATUS.SUCESS && experts && experts.results && experts.results.length > 0 ? (
                    experts.results.map((row: any) => (
                      <TableRowItem
                        key={row.id}
                        row={row}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                        onToggleDisable={handleToggleDisable}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} className={classes.noUsersMessage}>
                        <Error message={'There are no experts added as of yet. You can add a expert to see it here.'} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            <DeleteModal
              open={showDeleteModal}
              onCancel={cancelDelete}
              onConfirm={confirmDelete}
            />
            <DisableModal
              open={showDisableModal}
              onCancel={handleDisableCancel}
              onConfirm={handleDisableConfirm}
              isDisabled={expertToDisable ? expertToDisable.is_blocked : false}
            />
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
            itemsPerPage={itemsPerPage}
          />
          <SnackbarNotification
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleSnackbarClose}
          />
        </>
      )}
    </>
  );
};

export default ExpertListTable;
