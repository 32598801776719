import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { taskDataProps, UploadPayload, UploadState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/taskDataExtraReducer';

const initialState: taskDataProps = {
  taskData: {
    message : '',
    data: null,
    error: null
  },
  error: null,
  getTaskDataStatus: STATUS.NOT_STARTED,
};


  export const getTaskData = createAsyncThunk(
    'project/company/export/get',
    async (payload: UploadPayload = {project_id: ''}, thunkAPI) => {
      const { search, page, pageSize, sort, order, project_id, type } = payload;
      const queryString = new URLSearchParams({
        ...(project_id && { project_id }),
      }).toString();
      const response = await api.get(`project/company/export?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

 
const taskDataSlice = createSlice({
  name: 'taskData',
  initialState,
  reducers: {
    setGetTaskDataStatus(state, action) {
      state.getTaskDataStatus = action.payload
    }
  },
  extraReducers
});


export const { setGetTaskDataStatus } = taskDataSlice.actions
export default taskDataSlice.reducer;