import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { AddButton } from "../../button/button";
import { Autocomplete, Checkbox, CircularProgress, Divider, List, ListItem, ListItemText, TextField, Typography, } from "@mui/material";
import SnackbarNotification from "../../snackbar/snackbarNotification";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/appStore";
import { STATUS } from "../../../utils/status";
import { deleteVendorAction, fetchVendorsAction, getVendorAction, onBoardNewVendorAndAdminAction, setOnBoardNewVendorAndAdminStatus, setUpdateVendorAndAdminStatus, updateVendorAction } from "../../../redux/slices/vendor";

interface DrawerProps {
    setIsDrawerOpen?: any,
    isDrawerOpen?: boolean,
    selectedRow?: any,
    isRowClicked?: any
}

const VendorDrawerContent: React.FC<DrawerProps> = ({ setIsDrawerOpen, isDrawerOpen, selectedRow, isRowClicked }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState({
        "export_data": [false],
        "project_analytics": [false],
        "task_allocation_single": [false],
        "task_allocation_bulk": [false],
        "task_unallocation_single": [false],
        "task_unallocation_bulk": [false],
        "review_allocation_single": [false],
        "review_allocation_bulk": [false],
        "review_unallocation_single": [false],
        "review_unallocation_bulk": [false],
        "show_completed_task_details": [false],
    })
    const [vendorId, setVendorId] = useState("")
    const { id } = useParams();
    const updateVendorStatus = useSelector((state: RootState) => state.vendors.deleteVendorStatus)
    const Vendors = useSelector((state: RootState) => state.vendors.vendors)
    const getStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)
    const createVendorStatus = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminStatus);
    const [selectedVendor, setSelectedVendor] = useState<any | null>(null);
    const [isAddVendorBtnEnable, setIsAddVendorBtnEnable] = useState(false)

    console.log(selectedRow)

    useEffect(()=>{
        dispatch(fetchVendorsAction({project_id:id}));
    },[selectedRow])

    useEffect(() => {
        if (selectedRow && selectedRow.vendor_id) {
            setVendorId(selectedRow.vendor_id);
            setSelectedVendor({
                vendor_id: selectedRow.vendor_id,
                vendor_name: selectedRow.user_first_name,
                vendor_pseudo_name: selectedRow.company_pseudo_name,
            });
            setCheckedItems({
                export_data: [selectedRow.permissions.export_data[0] || false],
                project_analytics: [selectedRow.permissions.project_analytics[0] || false],
                task_allocation_single: [selectedRow.permissions.task_allocation_single[0] || false],
                task_allocation_bulk: [selectedRow.permissions.task_allocation_bulk[0] || false],
                task_unallocation_single: [selectedRow.permissions.task_unallocation_single[0] || false],
                task_unallocation_bulk: [selectedRow.permissions.task_unallocation_bulk[0] || false],
                review_allocation_single: [selectedRow.permissions.review_allocation_single[0] || false],
                review_allocation_bulk: [selectedRow.permissions.review_allocation_bulk[0] || false],
                review_unallocation_single: [selectedRow.permissions.review_unallocation_single[0] || false],
                review_unallocation_bulk: [selectedRow.permissions.review_unallocation_bulk[0] || false],
                show_completed_task_details: [selectedRow.permissions.show_completed_task_details[0] || false],
            });
        }
    }, [selectedRow]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const debouncedResults = useMemo(() => { return debounce(handleSearchChange, 300); }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (
        message: string,
        severity: "success" | "error" | "info" | "warning"
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        dispatch(getVendorAction({
            search_query: searchTerm,
        }));
    }, [dispatch, searchTerm]);

    const handleAutocompleteInput = (e: any) => {
        debouncedResults(e)
    };

    useEffect(() => {
        if (getStatus === STATUS.SUCESS) {
            setOptions(Vendors.results)
        }
    }, [getStatus])

    const handleAddVendor = () => {
        if(selectedRow)
        {
            dispatch(deleteVendorAction({
                id: selectedRow.id,
                permissions: checkedItems,
            }))
        }
        else
        {
            dispatch(onBoardNewVendorAndAdminAction({
                type: "VENDOR",
                id: vendorId,
                permissions: checkedItems,
                project_id: id
            }))
        }
        
    }

    useEffect(() => {
        const handleCreateVendorStatus = () => {
            if (createVendorStatus === STATUS.SUCESS) {
                handleSnackbar('Vendor Added to Project Successfully', 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchVendorsAction({ project_id: id }))
            }
            if (createVendorStatus === STATUS.FAILED) {
                handleSnackbar('This Vendor already exists on this project', 'error');
            }
        }
        handleCreateVendorStatus();
        return () => {
            dispatch(setOnBoardNewVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [createVendorStatus]);

    useEffect(() => {
        const handleUpdateVendorStatus = () => {
            if (updateVendorStatus === STATUS.SUCESS) {
                handleSnackbar('Vendor Permissions updated Successfully', 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchVendorsAction({ project_id: id }))
            }
            if (updateVendorStatus === STATUS.FAILED) {
                handleSnackbar('Error occured, please try again.', 'error');
            }
        }
        handleUpdateVendorStatus();
        return () => {
            dispatch(setUpdateVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [updateVendorStatus]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCheckedItems((prev) => {
            switch (name) {
                case "allowAll":
                    return {
                        export_data: [checked],
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],
                        review_allocation_single: [checked],
                        review_allocation_bulk: [checked],
                        review_unallocation_single: [checked],
                        review_unallocation_bulk: [checked],
                        show_completed_task_details: [checked],
                        project_analytics: [checked],
                    };

                case "allowExportData":
                    return {
                        ...prev,
                        export_data: [checked],
                    };

                case "allowProjetAnalytics":
                    return {
                        ...prev,
                        project_analytics: [checked],
                    };

                case "allowTasking":
                    return {
                        ...prev,
                        ...prev,
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],
                    };

                case "singleTaskAllocation":
                case "bulkTaskAllocation":
                    return {
                        ...prev,
                        task_allocation_single: name === "singleTaskAllocation" ? [checked] : prev.task_allocation_single,
                        task_allocation_bulk: name === "bulkTaskAllocation" ? [checked] : prev.task_allocation_bulk,
                    };

                case "singleTaskUnallocation":
                case "bulkTaskUnallocation":
                    return {
                        ...prev,
                        task_unallocation_single: name === "singleTaskUnallocation" ? [checked] : prev.task_unallocation_single,
                        task_unallocation_bulk: name === "bulkTaskUnallocation" ? [checked] : prev.task_unallocation_bulk,
                    };


                case "allowReviewing":
                    return {
                        ...prev,
                        review_allocation_single: [checked],
                        review_allocation_bulk: [checked],
                        review_unallocation_single: [checked],
                        review_unallocation_bulk: [checked],
                    };

                case "singleReviewAllocation":
                case "bulkReviewAllocation":
                    return {
                        ...prev,
                        review_allocation_single: name === "singleReviewAllocation" ? [checked] : prev.review_allocation_single,
                        review_allocation_bulk: name === "bulkReviewAllocation" ? [checked] : prev.review_allocation_bulk,
                    };

                case "singleReviewUnallocation":
                case "bulkReviewUnallocation":
                    return {
                        ...prev,
                        review_unallocation_single: name === "singleReviewUnallocation" ? [checked] : prev.review_unallocation_single,
                        review_unallocation_bulk: name === "bulkReviewUnallocation" ? [checked] : prev.review_unallocation_bulk,
                    };

                case "viewCompletedTaskDetails":
                    return {
                        ...prev,
                        show_completed_task_details: [checked],
                    };
                default:
                    return prev;
            }
        });
    };

    useEffect(() => {   
        const hasPermissionSelected = Object.values(checkedItems).some((data) => 
            data.some((d) => d === true)
        );
            setIsAddVendorBtnEnable(hasPermissionSelected)
        
    }, [checkedItems])
  

    return (
        <>
            <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>{selectedRow ? 'Edit Vendor': 'Add Vendor'}</Typography>
            <Divider />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "20px",
                }}
            >
                <Typography sx={{ marginTop: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Vendor</Typography>
                <Autocomplete
                    sx={{ width: 750 }}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    disabled={selectedRow}
                    autoSelect
                    value={selectedVendor}
                    getOptionLabel={(option) => 
                        option.vendor_pseudo_name ? `${option.vendor_name} (${option.vendor_pseudo_name})` : option.vendor_name
                    }                    
                    options={options}
                    loading={getStatus === STATUS.PENDING}
                    onChange={(event, value) => {
                        if (value) {
                            setVendorId(value.vendor_id);
                            setSelectedVendor(value);
                        } else {
                            setSelectedVendor(null);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Vendor name"
                            size="small"
                            sx={{ width: '100%' }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            onChange={(e) => { handleAutocompleteInput(e) }}
                        />
                    )}
                />
            </div>


            <div style={{ marginTop: "20px", display: 'flex' }}>
                <Typography sx={{ marginTop: '10px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Allow Permissions : </Typography>
                <Checkbox
                    name="allowAll"
                    checked={
                        checkedItems.export_data[0] &&
                        checkedItems.task_allocation_single[0] &&
                        checkedItems.task_allocation_bulk[0] &&
                        checkedItems.task_unallocation_single[0] &&
                        checkedItems.task_unallocation_bulk[0] &&
                        checkedItems.review_allocation_single[0] &&
                        checkedItems.review_allocation_bulk[0] &&
                        checkedItems.review_unallocation_single[0] &&
                        checkedItems.review_unallocation_bulk[0] &&
                        checkedItems.show_completed_task_details[0] &&
                        checkedItems.project_analytics[0]
                    }
                    onChange={handleCheckboxChange}
                    sx={{ marginLeft: '3px' }}
                />
                <span style={{ fontSize: "13px", marginTop: '1%' }} >All permissions</span>
            </div>

            <div style={{ display: 'flex', marginTop: '5px' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Export Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowExportData"
                        checked={checkedItems.export_data[0]}
                        onChange={handleCheckboxChange}
                        sx={{ marginLeft: '3px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Export data</span>

                </List>
            </div>

            <div style={{ display: 'flex' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowProjetAnalytics"
                        checked={checkedItems.project_analytics[0]}
                        onChange={handleCheckboxChange}
                        sx={{ marginLeft: '0.5px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Project analytics</span>

                </List>
            </div>

            <div style={{ position: "relative", marginTop: '10px' }}>
                <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task permissions : </Typography>
                <List>

                    <Checkbox
                        name="allowTasking"
                        checked={
                            checkedItems.task_allocation_single[0] &&
                            checkedItems.task_allocation_bulk[0] &&
                            checkedItems.task_unallocation_single[0] &&
                            checkedItems.task_unallocation_bulk[0]
                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Tasking</span>
                </List>

                <div style={{ marginTop: "-20px", position: "relative" }} >
                    <List>
                        <ListItem>
                            <Checkbox
                                name="singleTaskAllocation"
                                checked={checkedItems.task_allocation_single[0]}
                                onChange={handleCheckboxChange}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single task allocation</h1>} />

                            <Checkbox
                                name="bulkTaskAllocation"
                                checked={checkedItems.task_allocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />


                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk task allocation</h1>} />

                            <Checkbox
                                name="singleTaskUnallocation"
                                checked={checkedItems.task_unallocation_single[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single task unallocation</h1>} />

                            <Checkbox
                                name="bulkTaskUnallocation"
                                checked={checkedItems.task_unallocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk task unallocation</h1>} />
                        </ListItem>

                    </List>
                </div>
            </div>

            <div style={{ paddingTop: "0px", position: "relative" }}>
                <List>
                    <Checkbox
                        name="allowReviewing"
                        checked={
                            checkedItems.review_allocation_single[0] &&
                            checkedItems.review_allocation_bulk[0] &&
                            checkedItems.review_unallocation_single[0] &&
                            checkedItems.review_unallocation_bulk[0]
                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Reviewing</span>
                </List>

                <div style={{ marginTop: "-20px", position: "relative" }} >
                    <List>
                        <ListItem>

                            <Checkbox
                                name="singleReviewAllocation"
                                checked={checkedItems.review_allocation_single[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single review allocation</h1>} />


                            <Checkbox
                                name="bulkReviewAllocation"
                                checked={checkedItems.review_allocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk review allocation</h1>} />

                            <br />
                            <Checkbox
                                name="singleReviewUnallocation"
                                checked={checkedItems.review_unallocation_single[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single review unallocation</h1>} />
                            <Checkbox
                                name="bulkReviewUnallocation"
                                checked={checkedItems.review_unallocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk review unallocation</h1>} />
                        </ListItem>

                    </List>
                </div>
            </div>
            <div>
                <List>

                    <Checkbox
                        name="viewCompletedTaskDetails"
                        checked={checkedItems.show_completed_task_details[0]}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >View completed task details</span>
                </List>
            </div>


            <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                <AddButton onClick={handleAddVendor} disabled={createVendorStatus === STATUS.PENDING || !vendorId || !isAddVendorBtnEnable
                }>
                    {createVendorStatus === STATUS.PENDING ? (
                        <CircularProgress size={21} sx={{ mr: 1 }} />
                    ) : ('')}
                    {selectedRow ? 'Edit Vendor': 'Add Vendor'}
                </AddButton>
            </div>
            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default VendorDrawerContent