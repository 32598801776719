export const taskSearchTypesMenuItems = [
    {
        'value': 'task_name',
        'label': 'Task name'
    },
    {
        'value': 'task_allocation',
        'label': 'Tasker email / name'
    },
    {
        'value': 'review_allocation',
        'label': 'Reviewer email / name'
    },
    {
        'value': 'pseudo_name',
        'label': 'Pseudo name'
    },
]

export const taskFilterTypesMenuItems = [
    {
        'value': 'get_completed_tasks',
        'label': 'Show completed task'
    },
    {
        'value': 'get_unallocated_tasks',
        'label': 'Show unallocated task'
    }
]

export const taskSearchandFilterTypesMenuItems = [
    ...taskSearchTypesMenuItems,
    ...taskFilterTypesMenuItems
]