import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';
import { AddButton } from '../button/button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useParams } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import VendorStepHeading from './vendorAllocationHeading';
import VendorAllocationBody from './vendorAllocationBody';
import DeleteModal from '../modal/deleteModal';
import { createAnalyseBulkVendor, createBulkVendor, setCreateAnalyseStatus, setCreateVendorStatus } from '../../redux/slices/vendorList';
import VendorUnallocationBody from '../vendorBulkUnallocation/vendorUnallocationBody';
import VendorBulkAlocationAnalyseTable from './vendorBulkAllocAnalyseTable';

export default function VendorAllocationSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const createStatus = useSelector((state: RootState) => state.upload.createBulkAllocationStatus);
  const createVendorStatus = useSelector((state: RootState)=> state.vendorList.createVendorStatus)
  const createVendorAnalyseStatus = useSelector((state: RootState)=> state.vendorList.createAnalyseStatus)
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formDataArray, setFormDataArray] = useState([{ vendor: '', task_count: '', type: ''}]);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const handleOpenSubmitModal = () => {
    setOpenSubmitModal(true);
  }

  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  }

  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    const handleBeforeUnloadDialog = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBeforeUnloadDialog);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBeforeUnloadDialog);
    };
  }, []);

  const steps = ["Add Vendor", "Verify & Submit"];
  console.log({formDataArray})

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <VendorAllocationBody formDataArray={formDataArray} setFormDataArray={setFormDataArray} />
        );

      case 1:
        return (
          <VendorBulkAlocationAnalyseTable/>
        );

      default:
        return null;
    }
  };

  const handleNext = () => {
    if (activeStep !== 2) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
    if (activeStep === 0) {
      dispatch(createAnalyseBulkVendor({ payload: { project_id: id, data: formDataArray }}));
    }
  };

  const handleAllocate = () => {
    const payload = {
      project_id: id,
      data: formDataArray
    }
    dispatch(createBulkVendor(payload))
    setOpenSubmitModal(false)
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log(activeStep)

  React.useEffect(() => {
  if (createVendorStatus === STATUS.SUCESS) {
      handleSnackbar('Bulk allocated successfully', 'success');
      setTimeout(() => {
        navigate(`/project/${id}?tab=task`);
      }, 1000);
    }

    if (createVendorStatus === STATUS.FAILED) {
      handleSnackbar('Not enough unallocated tasks for the requested number of allocations.', 'error');
    }
    return () => {
      if (createVendorStatus === STATUS.SUCESS) {
        dispatch(setCreateVendorStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createVendorStatus, navigate, dispatch]);

console.log(createVendorAnalyseStatus)

  React.useEffect(() => {
    if (createVendorAnalyseStatus === STATUS.SUCESS) {
      if (activeStep === 0) {
        setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
      }
    }

    if (createVendorAnalyseStatus === STATUS.FAILED) {
      handleSnackbar('Error Occured, Please try again!', 'error');
    }
  return () => {
    if (createVendorAnalyseStatus === STATUS.SUCESS) {
       dispatch(setCreateAnalyseStatus(STATUS.NOT_STARTED));
    }
   };
}, [createVendorAnalyseStatus, navigate, dispatch]);

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const isNextDisabled = () => {
    return false;
  };

  console.log({createVendorAnalyseStatus})
  console.log({createVendorStatus})
console.log({shbfhjre:  formDataArray.some((data) => 
  !data.vendor || !data.task_count || !data.type
)})
  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <section className="fixed top-0 inset-x-0 h-[10px] z-40 drop-shadow bg-[#F7F9FA]" style={{ marginLeft: '4%' }}>
        <VendorStepHeading />
      </section>
      <section className="fixed top-0 inset-x-0 h-[88px] z-40 drop-shadow mt-44 ml-52 mb-56">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </section>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '4rem' }}>
        <section
          style={{
             height: 'calc(100vh - 400px)',
            overflowY: 'auto',
            padding: '12px, 0px, 12px, 0px',
            marginBottom: '15rem',
            marginTop: '14rem',
            flexGrow: 1
          }}
        >
          {renderStepContent(activeStep)}
        </section>
      </Box>
      <section className="fixed bottom-0 inset-x-0 h-[58px] bg-white z-40 drop-shadow">
        <Box
          sx={{
            mt: '0.1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            mr: '0.4rem'
          }}
        >
          {activeStep !== 0 && (
            <AddButton variant="contained" onClick={handleBack}>
              Previous
            </AddButton>
          )}

          <AddButton
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleOpenSubmitModal : handleNext}
            disabled={isNextDisabled() || formDataArray.some((data) => 
              !data.vendor || !data.task_count || !data.type
            )}
          >
            {activeStep === steps.length - 1 ? 'Allocate' : 'Next'}
          </AddButton>
        </Box>
      </section>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <DeleteModal 
      open={openSubmitModal} 
      onCancel={handleCloseSubmitModal} 
      onConfirm={handleAllocate}
      deleteText='Allocate'
      isButtonLoading={createStatus === STATUS.PENDING}
      />
    </div>
  );
}
