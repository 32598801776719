import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { GetPayload, ProjectTypeState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/projectExtraReducers';

const initialState: ProjectTypeState = {
  association_type: '',
  permissions: {},
  project: {},
  projects: {
    task_category: { name: '', id: '' },
    model: '',
    model_api_endpoint: '',
    default_prompt: '',
    model_api_key: '',
    payment_type: '',
    review_amount: '',
    task_amount: '',
    annotation_method_id: '',
    ideal_review_time: '',
    max_user_per_task: '',
    auto_submit_task: false,
    ideal_task_time: '',
    task_category_id: '',
    data_category: '',
    task_guidelines: '',
    reviewer_guidelines: '',
    expert_guidelines: '',
    description: '',
    name: '',
    review_setting: [''],
    id: '',
    status: '',
    count: undefined,
    results: []
  },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  projectTypeOptions: [],
  taskCategoryOptions: [],
  getProjectTypesStatus: STATUS.NOT_STARTED,
  getTaskCategoryStatus: STATUS.NOT_STARTED,
  projectExperts: [],
  taskExperts: [],
  reviewExperts: [],
  getProjectExpertsStatus: STATUS.NOT_STARTED
  
};

export const createProject = createAsyncThunk(
  'project/company/POST',
  async (payload: any, thunkAPI) => {
    const response = await api.post('project/company/', payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
)

export const getProject = createAsyncThunk(
  'project/user/get',
  async (payload: GetPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order, id, fields, paginate, project_id } = payload;
    const queryString = new URLSearchParams({
      ...(id && { id }),
      ...(project_id && { project_id }),
      ...(search && { search }),
      ...(page && { page: page.toString() }),
      ...(pageSize && { page_size: pageSize.toString() }),
      ...(paginate && { paginate: paginate.toString() }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(fields && { fields })
    }).toString();
    const response = await api.get(`project/user?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const updateProject = createAsyncThunk(
  'project/company/patch',
  async (payload: any, thunkAPI) => {
    const response = await api.patch(`project/company/`, payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const fetchProjectTypes = createAsyncThunk(
  'project/types/fetch',
  async (payload: { data_category: string, paginate?: any }, thunkAPI) => {
    const { data_category, paginate } = payload
    const queryString = new URLSearchParams({
      ...(Object.keys('data_category') && { data_category }),
      ...(Object.keys(payload).includes('paginate') && { paginate }),
    }).toString();
    const response = await api.get(`annotation-methods?${queryString}`);
    const { status, data } = response
    if (status === 200) {
      if (paginate === false) {
        return data.map((method: any) => ({
          value: method.value,
          name: method.name,
          id: method.id
        }));
      } else {
        return data.results.map((method: any) => ({
          value: method.value,
          name: method.name,
          id: method.id
        }));
      }
    } else {
      return thunkAPI.rejectWithValue({ response, data, payload });
    }
  }
);

export const fetchTaskCategories = createAsyncThunk(
  'project/categories/fetch',
  async (payload: { annotation_method: string, paginate?: any }, thunkAPI) => {
    const { annotation_method, paginate } = payload
    const queryString = new URLSearchParams({
      ...(annotation_method && { annotation_method }),
      ...(Object.keys(payload).includes('paginate') && { paginate }),
    }).toString();
    const response = await api.get(`task/category?${queryString}`)
    const { status, data } = response
    if (status === 200) {
      if (paginate === false) {
        return data.map((category: any) => ({
          value: category.value,
          name: category.name,
          id: category.id
        }));
      } else {
        return data.results.map((category: any) => ({
          value: category.value,
          name: category.name,
          id: category.id
        }));
      }
    } else {
      return thunkAPI.rejectWithValue({ response, data, payload });
    }
  }
);

export const getProjectExperts = createAsyncThunk(
  'project/export/get',
  async (payload: any, thunkAPI) => {
    const { is_blocked, type, project_id, paginate, fields, search_query } = payload
    const queryString = new URLSearchParams({
      ...(type && { type }),
      ...(project_id && { project_id }),
      ...(Object.keys(payload).includes('paginate') && { paginate }),
      ...(Object.keys(payload).includes('is_blocked') && { is_blocked }),
      ...(search_query && { search_query }),
      ...(fields && { fields })
    }).toString();
    const response = await api.get(`project/company/expert?${queryString}`)
    const { status, data } = response
    if (status === 200) {
      if (paginate === false) {
        return {
          'data':data.map((expert: any) => expert),
          ...(type && { type }),
        };
      } else {
        return {
          'data':data.results.map((expert: any) => expert),
          ...(type && { type }),
        }
      }
    } else {
      return thunkAPI.rejectWithValue({ response, data, payload });
    }

  }
)

const addProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCreateProjectStatus(state, action) {
      state.createStatus = action.payload
    },
    setUpdateProjectStatus(state, action) {
      state.updateStatus = action.payload
    },
    setGetProjectStatus(status, action) {
      status.getStatus = action.payload
    }
  },
  extraReducers
});

export const { setCreateProjectStatus, setUpdateProjectStatus, setGetProjectStatus } = addProjectSlice.actions;
export default addProjectSlice.reducer;
