import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Divider, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListofActiveProjects from './ListofActiveProjects';
import Linechart from './Linechart';
import AnalyticsListTable from '../../components/analytics/taskCompletedList';
import { getExpertAnalytics } from '../../redux/slices/analytics/experts';
import DashboardHeading from '../../components/dashboard/dashboardHeading';
import { getMetricsAction, getProjectWiseAnalyticsAction, getPseudoNameWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';
import { STATUS } from '../../utils/status';
import ChartForPseudoname from './ChartForPseudoname';
import CompletedTaskTableRow from '../../components/Active Project List/CompletedTaskTableRow';
import ActiveProjectsList from '../../components/Active Project List/ActiveProjectsList';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';

const DashboardMain = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [tableSwitch, setTableSwitch] = useState("TASK")
  const {
    metrics, getMetricsStatus,
    pseudoNameWiseAnalytics, getPseudoNameWiseAnalyticsStatus,
    getProjectWiseAnalyticsStatus, projectWiseAnalytics
  } = useSelector((state: RootState) => state.dashboard)

  const handleChangeToggle = (event: any, table: string,) => {
    if (table !== null) {
      setTableSwitch(table);
      dispatch(getExpertAnalytics({ project_id: id, type: event.target.value, association_type: "Admin" }))
    }
  };

  useEffect(() => {
    // dispatch(getAnalytics({ project_id: "", type: "all_project" }))
    dispatch(getMetricsAction({association_type: "COMPANY"}))
    dispatch(getPseudoNameWiseAnalyticsAction())
    dispatch(getProjectWiseAnalyticsAction({association_type: "COMPANY"}))
  }, [dispatch])

  // console.log({ projectWiseAnalytics })

  return (

    <div style={{ width: '100vw', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <DashboardHeading />
      </Box>
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>

        {/* Table Layout */}
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '1rem',
          boxShadow: 2,
        }}>

          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: "wrap", gap: "20px", marginBottom: '4%' }}>

            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#6A9C89',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].active_projects_count
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Active Projects</h3>
              </div>
            </Paper>

            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#173B45',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].total_tasks
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Task Added</h3>
              </div>
            </Paper>

            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#ffa726',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_allocated ? analytics.total_tasks_allocated : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].total_tasks_allocated
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Task Allocated</h3>

              </div>
            </Paper>

            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#A04747',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].not_started_tasks_count
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Task Not Started</h3>
              </div>
            </Paper>

            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#914F1E',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_completed ? analytics.total_tasks_completed : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].completed_tasks_count
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Total Allocated Task Completed</h3>
              </div>
            </Paper>


            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#41B3A2',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].active_task_count
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Active Task</h3>
              </div>
            </Paper>





            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#674188',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].avg_task_completeing_per_day
                        : 0
                  }
                </p>
                <h3 style={{ margin: "-5px", fontSize: '14px', fontWeight: '500' }}>Avg Daily Task Completion</h3>
              </div>
            </Paper>



            <Paper
              elevation={3}
              style={{
                marginRight: '20px',
                width: '22%',
                height: '100px',
                padding: '10px',
                background: '#C75B7A',
                color: '#fff',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>
                  {/* {analytics && analytics.total_tasks_added ? analytics.total_tasks_added : '0'} */}
                  {
                    getMetricsStatus === STATUS.PENDING ?
                      <CircularProgress size={15} />
                      : getMetricsStatus === STATUS.SUCESS ?
                        metrics.data[0].avg_time_taken_to_complete
                        : 0
                  }
                </p>
                <h3 style={{ margin: 0, fontSize: '14px', fontWeight: '500' }}>Avg Tasking Time</h3>
              </div>
            </Paper>

          </div>
          <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '30px' }}>Daily completion activity by pseudo name</Typography>
          <Box>
            <Linechart data={pseudoNameWiseAnalytics.data} dataStatus={getPseudoNameWiseAnalyticsStatus} />
          </Box>

          <Typography sx={{ marginTop: '40px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '30px' }}>Daily task completion </Typography>
          <Box>
            <ChartForPseudoname data={pseudoNameWiseAnalytics.data} dataStatus={getPseudoNameWiseAnalyticsStatus} />
          </Box>


          <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>List of active projects</Typography>
          <ActiveProjectsList 
            data={projectWiseAnalytics} 
            dataStatus={getProjectWiseAnalyticsStatus} 
          />

          {/* <Typography sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '20px', lineHeight: '14px', marginBottom: '20px' }}>List of active projects</Typography>

          <ToggleButtonGroup
            color="standard"
            value={tableSwitch}
            exclusive
            onChange={handleChangeToggle}
          >
            <ToggleButton
              value="TASK"
              style={{
                border: 'none',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                color: tableSwitch === 'TASK' ? '#03BBD2' : '#6C6C6C',
                borderBottom: tableSwitch === 'TASK' ? '3px solid #03BBD2' : 'none',
                backgroundColor: 'inherit',
                textTransform: 'none'
              }}
            >
              Tasker
            </ToggleButton>
            <ToggleButton
              value="REVIEW"
              style={{
                border: 'none',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                color: tableSwitch === 'REVIEW' ? '#03BBD2' : '#6C6C6C',
                borderBottom: tableSwitch === 'REVIEW' ? '3px solid #03BBD2' : 'none',
                backgroundColor: 'inherit',
                textTransform: 'none'
              }}
            >
              Reviewer
            </ToggleButton>
          </ToggleButtonGroup>
          <AnalyticsListTable /> */}



          {/* <img style={{ width: '128px', height: '140px', marginLeft: '45%' }} src={noData} alt="No Data" /> */}
          {/* <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16', fontWeight: '600', lineHeight: '24px', textAlign: 'center' }}>No Stats to Display Currently.</Typography> */}
        </Paper>
      </Box>
    </div>
  );
};

export default DashboardMain;
