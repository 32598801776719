import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from "../../../assets/styles/listTableStyle";
import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { AddButton } from "../../button/button";
import { Autocomplete, Checkbox, CircularProgress, Divider, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import SnackbarNotification from "../../snackbar/snackbarNotification";
import { getUser } from "../../../redux/slices/userSlice/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/appStore";
import { STATUS } from "../../../utils/status";
import { deleteVendorAction, fetchAdminsAction, onBoardNewVendorAndAdminAction, setOnBoardNewVendorAndAdminStatus, setUpdateVendorAndAdminStatus } from "../../../redux/slices/vendor";

interface DrawerProps {
    setIsDrawerOpen: any,
    isDrawerOpen: any,
    selectedRow?: any,
    isRowClicked?: any
}
interface CheckedItems {
    project_analytics: boolean[];
    export_data: boolean[];
    task_allocation: boolean[];
    review_allocation: boolean[];
    project_details: boolean[];
    project_config: boolean[];
    project_payments: boolean[];
    project_users: boolean[];
    completing_task_action_details: any[];
}

const AdminDrawerContent: React.FC<DrawerProps> = ({ setIsDrawerOpen, isRowClicked, selectedRow }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [adminId, setAdminId] = useState("")
    const [checkedItems, setCheckedItems] = useState<CheckedItems>({
        project_analytics: [false],
        export_data: [false],
        task_allocation: [false],
        review_allocation: [false],
        project_details: [false],
        project_config: [false],
        project_payments: [false],
        project_users: [false],
        completing_task_action_details: [false]
    });

    const users = useSelector((state: RootState) => state.users.users);
    const updateAdminStatus = useSelector((state: RootState) => state.vendors.deleteVendorStatus)
    const getStatus = useSelector((state: RootState) => state.users.getStatus)
    const AdminStatus = useSelector((state: RootState) => state.vendors.onBoardNewVendorAndAdminStatus)
    const { id } = useParams()
    const [selectedAdmin, setSelectedAdmin] = useState<any | null>(null);
    const [isAddVendorBtnEnable, setIsAddVendorBtnEnable] = useState(false)
    const association_type = useSelector((state: RootState) => state.projects.association_type);

    useEffect(() => {
        dispatch(fetchAdminsAction({ project_id: id }));
    }, [selectedRow])

    useEffect(() => {
        if (selectedRow && selectedRow.user_id) {
            setAdminId(selectedRow.user_id);
            setSelectedAdmin({
                admin_id: selectedRow.user_id,
                first_name: selectedRow.user_first_name,
                email: selectedRow.user_email,
            });
            setCheckedItems({
                export_data: [selectedRow.permissions.export_data?.[0] || false],
                project_analytics: [selectedRow.permissions.project_analytics?.[0] || false],
                task_allocation: [selectedRow.permissions.task_allocation?.[0] || false],
                review_allocation: [selectedRow.permissions.review_allocation?.[0] || false],
                project_details: [selectedRow.permissions.project_details?.[0] || false],
                project_config: [selectedRow.permissions.project_config?.[0] || false],
                project_payments: [selectedRow.permissions.project_payments?.[0] || false],
                project_users: [selectedRow.permissions.project_users?.[0] || false],
                completing_task_action_details: []
            });
        }
    }, [selectedRow]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleSearchChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (
        message: string,
        severity: "success" | "error" | "info" | "warning"
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        dispatch(getUser({
            search: searchTerm,
            is_disabled: false
        }));
    }, [dispatch, searchTerm]);


    const handleAutocompleteInput = (e: any) => {
        debouncedResults(e)
    };

    useEffect(() => {
        if (getStatus === STATUS.SUCESS) {
            setOptions(users.results)
        }
    }, [getStatus])


    const handleAddAdmin = () => {
        if (selectedRow) {
            dispatch(deleteVendorAction({
                id: selectedRow.id,
                permissions: checkedItems,
            }))
        }
        else {
            dispatch(onBoardNewVendorAndAdminAction({
                type: association_type,
                id: adminId,
                project_id: id,
                permissions: checkedItems
            }))
        }
    }

    useEffect(() => {
        const handleStatus = () => {
            if (AdminStatus === STATUS.SUCESS) {
                handleSnackbar('Admin Added to Project Successfully', 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000)
                dispatch(fetchAdminsAction({ project_id: id }))
            }
            if (AdminStatus === STATUS.FAILED) {
                handleSnackbar('This Admin already exists on this project', 'error');
            }
        };

        handleStatus();
        return () => {

            dispatch(setOnBoardNewVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [AdminStatus, navigate, dispatch]);

    useEffect(() => {
        const handleAdminVendorStatus = () => {
            if (updateAdminStatus === STATUS.SUCESS) {
                handleSnackbar('Admin Permissions updated Successfully', 'success');
                setTimeout(() => {
                    setIsDrawerOpen(false)
                }, 1000);
                dispatch(fetchAdminsAction({ project_id: id }))
            }
            if (updateAdminStatus === STATUS.FAILED) {
                handleSnackbar('Error occured, please try again.', 'error');
            }
        }
        handleAdminVendorStatus();
        return () => {
            dispatch(setUpdateVendorAndAdminStatus(STATUS.NOT_STARTED));
        };
    }, [updateAdminStatus]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCheckedItems((prev) => {
            switch (name) {
                case "allowAll":
                    return {
                        project_analytics: [checked],
                        export_data: [checked],
                        task_allocation: [checked],
                        review_allocation: [checked],
                        project_details: [checked],
                        project_config: [checked],
                        project_payments: [checked],
                        project_users: [checked],
                        completing_task_action_details: checked ? [true, "read", "edit"] : [false]
                    };


                case "allowTasking":
                    return {
                        ...prev,
                        task_allocation: [checked],
                        review_allocation: [checked],
                    };

                case "allowExportData":
                    return {
                        ...prev,
                        export_data: [checked],
                    };

                case "tasking":
                    return {
                        ...prev,
                        task_allocation: [checked],
                    };

                case "reviewing":
                    return {
                        ...prev,
                        review_allocation: [checked],
                    };

                case "allowProjetAnalytics":
                    return {
                        ...prev,
                        project_analytics: [checked],
                    };

                case "projectDetails":
                    return {
                        ...prev,
                        project_details: [checked],
                    };

                case "projectUsers":
                    return {
                        ...prev,
                        project_users: [checked],
                    }

                case "projectConfigs":
                    return {
                        ...prev,
                        project_config: [checked],
                    };

                case "paymentSettings":
                    return {
                        ...prev,
                        project_payments: [checked],
                    };

                case "viewCompletedTaskDetails":
                    return {
                        ...prev,
                        show_completed_task_details: [checked],
                    };
                    case "edit":
                        return {
                            ...prev,
                            completing_task_action_details: checked
                                ? [true, "edit"]
                                : [false] 
                        };
                    
                    case "read":
                        return {
                            ...prev,
                            completing_task_action_details: checked
                                ? [true, "read"]
                                : [false]
                        };
                    


                default:
                    return prev;
            }
        });
    };


    useEffect(() => {
        const hasPermissionSelected = Object.values(checkedItems).some((data) => {
            if (typeof data === 'object' && 'edit' in data && 'read' in data) {
                return data.some((d: any) => d === true) || data.some((d: any) => d === true);
            }
            return data.some((d: any) => d === true);
        });

        setIsAddVendorBtnEnable(hasPermissionSelected);
    }, [checkedItems]);

    return (
        <>
            <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>{selectedRow ? 'Edit Admin' : 'Add Admin'}</Typography>
            <Divider />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "20px",
                }}
            >
                <Typography sx={{ marginTop: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Admin Name</Typography>
                <Autocomplete
                    sx={{ width: 750 }}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    autoSelect
                    getOptionLabel={(option) =>
                        option.last_name ? `${option.first_name} (${option.last_name}) ${option.email}` : `${option.first_name} (${option.email})`
                    }
                    options={options}
                    disabled={selectedRow}
                    value={selectedAdmin}
                    loading={getStatus === STATUS.PENDING}
                    onChange={(event, value) => {
                        if (value) {
                            setAdminId(value.id);
                            setSelectedAdmin(value);
                        } else {
                            setSelectedAdmin(null);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Admin"
                            size="small"
                            sx={{ width: '100%' }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            onChange={handleAutocompleteInput}
                        />
                    )}
                />
            </div>


            <div style={{ marginTop: "20px", display: 'flex' }}>
                <Typography sx={{ marginTop: '10px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Allow Permissions : </Typography>
                <Checkbox
                    name="allowAll"
                    checked={
                        checkedItems.export_data[0] &&
                        checkedItems.task_allocation[0] &&
                        checkedItems.review_allocation[0] &&
                        checkedItems.project_details[0] &&
                        checkedItems.project_config[0] &&
                        checkedItems.project_payments[0] &&
                        checkedItems.project_analytics[0] &&
                        checkedItems.project_users[0]
                    }
                    onChange={handleCheckboxChange}
                />

                <span style={{ fontSize: "13px", marginTop: '1%' }} >All permissions</span>
            </div>

            <div style={{ display: 'flex', marginTop: '5px' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Export Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowExportData"
                        checked={checkedItems.export_data[0]}
                        onChange={handleCheckboxChange}
                        sx={{ marginLeft: '3px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Export data</span>

                </List>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Permissions : </Typography>
                <List style={{ display: 'flex' }}>
                    <Checkbox
                        name="allowProjetAnalytics"
                        checked={checkedItems.project_analytics[0]}
                        onChange={handleCheckboxChange}
                        sx={{ marginLeft: '0.5px' }}
                    />
                    <span style={{ fontSize: "13px", marginTop: '10px' }} >Project analytics</span>
                    <Checkbox
                        name="projectConfigs"
                        checked={checkedItems.project_config[0]}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Project configs</h1>} />

                    <Checkbox
                        name="projectDetails"
                        checked={checkedItems.project_details[0]}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Project details</h1>} />

                    <Checkbox
                        name="paymentSettings"
                        checked={checkedItems.project_payments[0]}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Payment settings</h1>} />

                    <Checkbox
                        name="projectUsers"
                        checked={checkedItems.project_users[0]}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >User Management</h1>} />
                </List>
            </div>

            <div style={{ position: "relative", marginTop: '10px' }}>
                <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task permissions : </Typography>
                {/* <List>

                    <Checkbox
                        name="allowTasking"
                        checked={
                            checkedItems.task_allocation[0] &&
                            checkedItems.review_allocation[0]
                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Tasking</span>

                </List> */}

                <div style={{ marginTop: "-10px", display: 'flex' }} >
                    <List>
                        <ListItem>
                            <Checkbox
                                name="tasking"
                                checked={checkedItems.task_allocation[0]}
                                onChange={handleCheckboxChange}
                                sx={{ marginLeft: '10%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Tasking</h1>} />

                            <Checkbox
                                name="reviewing"
                                checked={checkedItems.review_allocation[0]}
                                onChange={handleCheckboxChange}
                                sx={{ marginLeft: '20%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Reviewing</h1>} />
                        </ListItem>

                    </List>
                </div>
            </div>

            <div style={{ position: "relative", marginTop: '10px' }}>
                <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Completing Task Permissions : </Typography>

                <div style={{ marginTop: "-10px", display: 'flex' }} >
                    <List>
                        <ListItem>
                            <Checkbox
                                name="edit"
                                checked={checkedItems.completing_task_action_details.includes("edit")}
                                onChange={handleCheckboxChange}
                                sx={{ marginLeft: '15%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Edit</h1>} />

                            <Checkbox
                                name="read"
                                checked={checkedItems.completing_task_action_details.includes("read")}
                                onChange={handleCheckboxChange}
                                sx={{ marginLeft: '49%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Read</h1>} />
                        </ListItem>
                    </List>

                </div>
            </div>

            <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
                <AddButton
                    color="primary"
                    onClick={handleAddAdmin}
                    disabled={AdminStatus === STATUS.PENDING || !adminId || !isAddVendorBtnEnable}
                >
                    {AdminStatus === STATUS.PENDING ? (
                        <CircularProgress size={21} sx={{ mr: 1 }} />
                    ) : (
                        ''
                    )}
                    {selectedRow ? 'Edit Admin' : 'Add Admin'}
                </AddButton>
            </div>
            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    );
};

export default AdminDrawerContent;
