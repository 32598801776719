import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import TaskResponses from './taskResponses';
import { RootState } from '../../redux/store/appStore';
import { useSelector } from 'react-redux';

interface TaskDetailsAccordionProps {
    result: any;
    resultIndex: number;
}

function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export default function TaskDetailsAccordion({ result, resultIndex }: TaskDetailsAccordionProps) {

    const taskTasks = result.tasks.filter((task: any) => task.type === 'TASK');
    const reviewTasks = result.tasks.filter((task: any) => task.type === 'REVIEW');

    return (
        <>
            {result.tasks.filter((task: any) => task.type === 'TASK').length > 0 &&
                <div style={{ backgroundColor: 'white', marginBottom: '10px', paddingTop: '5px', borderRadius: '8px', margin: '2px' }}>
                    {result.tasks?.map((task: any, taskIndex: number) => {
                        const hasNewStructure = task.task_details.some((el:any) => el.type);
                        return (
                            <div key={taskIndex}>
                                {/* <Typography sx={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600', marginBottom: '20px' }} variant="h6" gutterBottom>
                                    Task Details :
                                </Typography>
                                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600', marginBottom: '20px', 'color':'black' }} variant="h6" gutterBottom>
                                    Task Id : {task.task_id}
                                </Typography>
                                {task.task_details.map((item: any, index: number) => {
                                    return (
                                        hasNewStructure ?
                                        <div key={index} className="mt-4">
                                            <div className="font-poppins font-semibold mt-2 text-tri-text text-sm">
                                                <strong style={{ marginBottom: '10px' }}>{toTitleCase(item.key)}:</strong>
                                                {!item.type || item.type === "text" ?
                                                    (<span className="font-normal ml-2">{item.value}</span>)
                                                    : item.type === "video" ?
                                                        (<video controls className="w-full">
                                                            <source src={item.value} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>)
                                                        : item.type === "image" ? (<img src={item.value} alt={`Explanation ${item.index}`} className="w-full" />)
                                                            : item.type === "audio" ?
                                                                (<audio controls className="w-full">
                                                                    <source src={item.value} type="audio/mpeg" />
                                                                    Your browser does not support the audio element.
                                                                </audio>)
                                                                : null
                                                }
                                            </div>
                                        </div>
                                        : <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px' }} key={index} variant="body1" paragraph>
                                        <Typography component="span" fontWeight="bold" sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
                                            {item.key}:
                                        </Typography>{' '}
                                        {item.value}
                                    </Typography>
                                    )
                                })} */}
                            </div>
                        )
                    })}
                    {result.tasks?.map((task: any, taskIndex: number) => (
                        <div key={taskIndex}>
                            { (task.evaluation_data && Object.keys(task.evaluation_data).includes('value')) ? task.evaluation_data?.value.map((detail: any, index: number) => (
                                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px' }} key={index} variant="body1" paragraph>
                                    <Typography component="span" fontWeight="bold" sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
                                        {detail.key}:
                                    </Typography>{' '}
                                    {detail.value}
                                </Typography>
                            )) : <></>}
                        </div>
                    ))}
                </div>
            }

            {result.tasks.filter((task: any) => task.type === 'TASK').length > 0 && (
                <Accordion defaultExpanded style={{ marginBottom: "20px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`task-panel${resultIndex + 1}-content`}
                        id={`task-panel${resultIndex + 1}-header`}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '600' }}>Task Submission by {result.first_name} ({result.email})</Typography>
                            <div>
                                {taskTasks.map((task: any, taskIndex: number) => (
                                    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', marginTop: '10px' }} key={taskIndex}>
                                        Start Time: {new Date(task.start_time).toLocaleString()} , End Time: {new Date(task.end_time).toLocaleString()}
                                    </Typography>
                                ))}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {taskTasks.map((task: any, taskIndex: number) => (
                                <TaskResponses key={taskIndex} task={task} taskIndex={taskIndex} results={result}/>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}

            {reviewTasks.length > 0 && (
                <Accordion defaultExpanded style={{}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`review-panel${resultIndex + 1}-content`}
                        id={`review-panel${resultIndex + 1}-header`}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '600' }}>Review Submission by {result.first_name} ({result.email})</Typography>
                            {reviewTasks.map((task: any, taskIndex: number) => (
                                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', marginTop: '10px' }} key={taskIndex}>
                                    Start Time: {new Date(task.start_time).toLocaleString()} , End Time: {new Date(task.end_time).toLocaleString()}
                                </Typography>
                            ))}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {reviewTasks.map((task: any, taskIndex: number) => (
                            <TaskResponses key={taskIndex} task={task} taskIndex={taskIndex} results={result}/>
                        ))}
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
}
