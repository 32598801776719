import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AnalyticsExpertState } from '../interfaces/interface';
import { getExpertAnalytics } from '../slices/analytics/experts';

const ExtraReducers = (builder: ActionReducerMapBuilder<AnalyticsExpertState>) => {
  builder
  .addCase(getExpertAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getExpertAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.analyticsExpert = action.payload;
  })
  .addCase(getExpertAnalytics.rejected, (state, action) => {
    state.getStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
