import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { CompanyState, GetPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/companyExtraReducer';

const initialState: CompanyState = {
  company: {
        message: '',
        data: {
          role: '', 
          actions: {
            read: false,
            create: false,
            update: false,
            delete: false,
            share: false,
          },
          email:'',
          modules: {
            dashboard: null, 
            admin: {
              table: {
                access: false,
                table_fields: [],
                table_actions: [],
                search: {
                  access: false,
                },
              },
            },
            experts: {
              table: {
                access: false,
                table_fields: [],
                table_actions: [],
                search: {
                  access: false,
                },
              },
            },
            projects: {
              table: {
                access: false,
                table_fields: [],
                table_actions: [],
                search: {
                  access: false,
                },
              },
            },
          },
        },
        error: null,
      },
  error: null,
  getStatus: STATUS.NOT_STARTED,
};

export const getUserAccess = createAsyncThunk(
        'company/user/access/get',
        async (payload: GetPayload = {}, thunkAPI) => {
          const { search, page, pageSize, sort, order } = payload;
          const queryString = new URLSearchParams({
            ...(search && { search }),
            ...(page && { page: page.toString() }),
            ...(pageSize && { page_size: pageSize.toString() }),
            ...(sort && { sort }),
            ...(order && { order }),
          }).toString();
          const response = await api.get(`company/user/access?${queryString}`);
          const { status, data } = response;
          if (status === 200) {
            return data;
          } else {
            return thunkAPI.rejectWithValue({ response, data });
          }
        }
      );

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers
});

export default companySlice.reducer;
