import React, { useEffect, useMemo, useState } from 'react';
import { Box, List, ListItem, ListItemText, Paper, TextField, Typography, Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { getTaskers } from '../../../redux/slices/expertSlice/addExpertSlice';
import { useParams } from 'react-router-dom';
import {  getProjectExperts, updateProject } from '../../../redux/slices/projectSlice/addProjectSlice';
import debounce from 'lodash.debounce';
import { STATUS } from '../../../utils/status';

interface Tasker {
  id: string;
  email: string;
}

interface FormState {
  taskers?: {
    expert: {
      id: string;
      email: string;
    };
  }[];
}

const InviteTasker = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedItems, setSelectedItems] = useState<Tasker[]>([]);
  const [deselectedItems, setDeselectedItems] = useState<Tasker[]>([]);
  const [items, setItems] = useState<Tasker[]>([]);
  const [searchQueryAvailable, setSearchQueryAvailable] = useState<string>('');
  const [searchQuerySelected, setSearchQuerySelected] = useState("")
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const { id } = useParams();
  const fetchedProject = useSelector((state: RootState) => state.projects.projects as Partial<FormState>);
  const [newTaskers, setNewTaskers] = useState<Tasker[]>([]);
  // const [inviteTaskerSearchQuery, setInviteTaskerSearchQuery] = useState("")

  const { getCompanyExpertsStatus, taskers } = useSelector((state:RootState) => state.experts)
  const { getProjectExpertsStatus, projectExperts, taskExperts } = useSelector((state: RootState) => state.projects)
  console.log({taskExperts})
  const getTaskerStatus = useSelector((state: RootState) => state.experts.getTaskersStatus)

  useEffect(() => {
      dispatch(getTaskers({ search: searchQueryAvailable, projectId: id || '' }))
  }, [dispatch, searchQueryAvailable, id]);

  useEffect(() => {
    setItems(taskers);
  }, [taskers]);

  const getUniqueTaskers = (taskers: Tasker[]): Tasker[] => {
    const uniqueTaskers: Tasker[] = [];
    const seenEmails = new Set<string>();

    taskers.forEach(tasker => {
      if (!seenEmails.has(tasker.email)) {
        uniqueTaskers.push(tasker);
        seenEmails.add(tasker.email);
      }
    });

    return uniqueTaskers;
  };

  const [previouslySelected, setPreviouslySelected] = useState<Tasker[]>([]);
  
  const handleSelect = (item: Tasker) => {
    const newSelected = getUniqueTaskers([...selectedItems, item]);
    const filteredTaskers = newSelected.filter((tasker) =>
      !fetchedProject.taskers?.some(
        (projectTasker: any) =>
          projectTasker.expert.id === tasker.id && projectTasker.expert.email === tasker.email
      )
    );

    setNewTaskers(filteredTaskers);
    setPreviouslySelected(newSelected);
    setSelectedItems(newSelected);

    const newTaskersForPayload = filteredTaskers.length > 0 ? [filteredTaskers[0].id] : [];
    const payload: any = {
      id: id,
      assign: {
        taskers: newTaskersForPayload,
      },
    };

    dispatch(updateProject(payload));
  };

  const handleDeselect = (item: any) => {
    setSelectedItems((prevSelected) => {
      const newSelected = prevSelected.filter(i => i.email !== item.email);
      return newSelected;
    });
  
    setItems((prevItems) => {
      const newItems = getUniqueTaskers([...prevItems, item]);
      return newItems;
    });

    const payload: any = {
      id: id,
      unassign: {
        taskers: [item.expert_id], 
      },
    };
  
    dispatch(updateProject(payload));
  
    setDeselectedItems((prevDeselected) => {
      const newDeselected = getUniqueTaskers([...prevDeselected, item]);
      return newDeselected;
    });
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchChangeAvailable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryAvailable(event.target.value);
  };
  
  const handleSearchChangeSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuerySelected(event.target.value);
  };

  const debouncedResultsAvailable = useMemo(() => { return debounce(handleSearchChangeAvailable, 300)}, []);
  const debouncedResultsSelected = useMemo(() => { return debounce(handleSearchChangeSelected, 300)}, []);


  useEffect(() => { 
    return () => {
      debouncedResultsAvailable.cancel();
      debouncedResultsSelected.cancel();
    };
  });

  const filteredTaskExperts = taskExperts.filter((item: { expert_email: string; }) =>
    item.expert_email.toLowerCase().includes(searchQuerySelected.toLowerCase())
  );

  const isTaskerSelected = (email: string) => {
    return taskExperts.some((tasker: { expert_email: string; }) => tasker.expert_email === email);
  };

  const availableTaskers = items.filter(item => !isTaskerSelected(item.email));
  const disabledTaskers = items.filter(item => isTaskerSelected(item.email));

  useEffect(() => {
    dispatch(getProjectExperts({
      type: 'TASK',
      paginate: false,
      is_blocked: false,
      project_id: id,
      fields: JSON.stringify(['id', 'expert.id', 'sub_project.id', 'expert.first_name', 'expert.last_name', 'expert.email', 'expert.pseudo_name', 'created_at', 'type']),
    }))
  }, [])

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Paper elevation={1} sx={{ height: '40vh', backgroundColor: '#FBFBFB' }}>
            {getTaskerStatus === STATUS.PENDING ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0' }}>
                <CircularProgress sx={{ marginTop: '40%' }} />
              </Box>
            ) : <></>}
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <TextField
                label={`Search Taskers (${availableTaskers.length} taskers available)`}
                variant="outlined"
                size="small"
                // value={searchQuery}
                onChange={debouncedResultsAvailable}
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon onClick={toggleSearch} style={{ cursor: 'pointer' }} />,
                }}
              />
            </Box>
            {items.length === 0 && getTaskerStatus === STATUS.SUCESS && (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: 'gray' }}>
                No taskers left to invite
              </Typography>
            )}
            {getTaskerStatus === STATUS.SUCESS && (
              <List sx={{ height: 'calc(100% - 96px)', overflow: 'auto' }}>
                {availableTaskers.map(item => (
                  <ListItem
                    key={item.email}
                    button
                    onClick={() => handleSelect(item)}
                    sx={{
                      '&:hover': { backgroundColor: '#f0f0f0' },
                      cursor: 'pointer',
                    }}
                  >
                    <ListItemText primary={item.email} />
                  </ListItem>
                ))}
                {disabledTaskers.map(item => (
                  <ListItem
                    key={item.email}
                    button
                    disabled
                    sx={{
                      color: 'gray',
                      cursor: 'not-allowed',
                    }}
                  >
                    <ListItemText primary={item.email} />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
        </Grid>
        <Grid item xs={5}>
          <Paper elevation={1} sx={{ height: '40vh', backgroundColor: '#FBFBFB' }}>
            { getProjectExpertsStatus === STATUS.PENDING && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0' }}>
                <CircularProgress sx={{ marginTop: '40%' }} />
              </Box>
            )}
            {getProjectExpertsStatus === STATUS.SUCESS && (
              <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <TextField
                  label={`Search Taskers (${filteredTaskExperts.length} selected)`}
                  variant="outlined"
                  size="small"
                  onChange={debouncedResultsSelected}
                  fullWidth
                  InputProps={{
                    endAdornment: <SearchIcon style={{ cursor: 'pointer' }} />,
                  }}
                />
              </Box>
            )}
            {filteredTaskExperts.length === 0 && getCompanyExpertsStatus === STATUS.SUCESS && (
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: 'gray' }}>
                No taskers selected
              </Typography>
            )}
            {getProjectExpertsStatus === STATUS.SUCESS && (
              <List sx={{ height: 'calc(100% - 96px)', overflow: 'auto' }}>
                {filteredTaskExperts.map((item: { expert_id: string; expert_email: string}) => {
                  console.log({item})
                  return (
                    <ListItem
                      key={item.expert_id}
                      button
                      onClick={() => handleDeselect(item)}
                      sx={{
                        '&:hover': { backgroundColor: '#f0f0f0' },
                        cursor: 'pointer',
                      }}
                    >
                      <ListItemText primary={item.expert_email} />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InviteTasker;
