import { useParams } from "react-router-dom";
import { useStyles } from "../../../assets/styles/listTableStyle";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { SearchBar } from "../../inputField/textfield";
import { AddButton } from "../../button/button";
import { CircularProgress, Drawer, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import SnackbarNotification from "../../snackbar/snackbarNotification";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import AdminDrawerContent from "./AdminDrawerContent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/appStore";
import { deleteVendorAction, fetchAdminsAction, getVendorAction } from "../../../redux/slices/vendor";
import TableHeader from "../../table/tableHeader";
import { STATUS } from "../../../utils/status";
import Error from "../../error/error";
import DisableModal from "../../projectList/disableModal";
import DeleteModal from "../../modal/deleteModal";
import PaginationEntries from "../../pagination/paginationEntries";
import AdminPagination from "./AdminPagination";
import TableRowItem from "./userAdminTableRowItem";

const UserAdminsList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDisableModal, setShowDisableModal] = useState(false)
    const [adminId, setAdminId] = useState("")
    const [adminToDisable, setAdminToDisable] = useState<any>(null)
    const [isEdit, setIsEdit] = useState(false)

    const classes = useStyles();
    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams()
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isClicked, setIsClicked] = useState(false);

    const adminList = useSelector((state: RootState) => state.vendors.ListOnBoardAdmins)
    const adminStatus = useSelector((state: RootState) => state.vendors.fetchAdminStatus)
    const adminTableAccess = useSelector((state: RootState) => state.company?.company?.data?.modules?.admin?.table?.access);

    const tableFields = ["User Name", "Project name", "email", "Actions"]
    const totalPages = Math.ceil((adminList.results.count || 0) / itemsPerPage);

    const capitalizeAll = (str: string) => {
        return str.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.toUpperCase())
            .join(' ');
    };
    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeRowsPerPage = (value: number) => {
        setItemsPerPage(value as number);
        setCurrentPage(1);
        dispatch(fetchAdminsAction({ search_query: searchTerm, page: 1, pageSize: value as number }));
    };
    const handleOnChange = (value: number) => {
        setItemsPerPage(value)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleSearchChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbar = (
        message: string,
        severity: "success" | "error" | "info" | "warning"
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        dispatch(
            fetchAdminsAction({
                paginate: true,
                search_query: searchTerm,
                project_id: id,
                page: currentPage,
                pageSize: itemsPerPage
            })
        )
    }, [dispatch, searchTerm, currentPage, itemsPerPage])

    const handleDisableCancel = () => {
        setShowDisableModal(false);
        setAdminToDisable(null)
    };

    const handleDisableConfirm = async () => {
        setShowDisableModal(false);
        if (adminToDisable) {
            const updatedStatus = !adminToDisable.is_blocked;
            try {
                await dispatch(deleteVendorAction({ is_blocked: updatedStatus, id: adminId }));
                dispatch(
                    getVendorAction({})
                );
                handleSnackbar('Success', 'success');
                dispatch(fetchAdminsAction({project_id: id}))
            } catch (error) {
                console.error('Failed to update user status', error);
            }
        }
        setAdminToDisable(null);
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
    };

    const confirmDelete = async () => {
        setShowDeleteModal(false);
        console.log(adminId)
        try {
            await dispatch(deleteVendorAction({ is_deleted: true, id: adminId }));
            dispatch(
                getVendorAction({})
            );
            handleSnackbar('Admin Deleted Successfully.', 'success');
            dispatch(fetchAdminsAction({project_id: id}))
        } catch (error) {
            handleSnackbar('Error occured please try again.', 'error');
        }
    };

    const handleToggleDisable = (row: any) => {
        setAdminId(row.id)
        setShowDisableModal(true);
        setAdminToDisable(row);
    };

    const handleDeleteClick = (userId: string) => {
        setAdminId(userId);
        setShowDeleteModal(true);
    };

    const handleClick = (row: any) => {
        setIsClicked(true);
        setIsDrawerOpen(true);
        setSelectedRow(row);
      }
    
      const handleAddClick = () =>{
        setIsClicked(false);
        setSelectedRow(null);
        setIsDrawerOpen(true);
      }


    return (
        <>
            <Drawer
                anchor={"right"}
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false)
                    setIsEdit(false)
                }}
                PaperProps={{ sx: { width: "70%", padding: "40px" } }}
            >
                <AdminDrawerContent setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} selectedRow={selectedRow} isRowClicked={isClicked} />
            </Drawer>


            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 0px 15px 0px" }}>
                <SearchBar onSearchChange={debouncedResults} />
                <div className={classes.actionsContainer}>
                    <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
                    <AddButton color="primary" onClick={handleAddClick}>
                        <LibraryAddIcon sx={{ mr: 1 }} />
                        Assign Admins
                    </AddButton>
                </div>
            </div>

            <TableContainer>
                <Table className={classes.tableContainer} aria-label='simple table'>
                    <TableHeader headers={tableFields.map(capitalizeAll)} />
                    <TableBody>
                        {
                            adminStatus === STATUS.PENDING ? (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <div style={{ position: 'relative', height: '10vh' }}>
                                            <CircularProgress
                                                size={35}
                                                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                adminList && adminList?.results?.results?.length > 0 ? (
                                    adminList.results?.results?.map((row: any) => (
                                        <TableRowItem
                                            key={row.id}
                                            row={row}
                                            onDeleteClick={handleDeleteClick}
                                            onToggleDisable={handleToggleDisable}
                                            handleClick={handleClick}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} className={classes.noUsersMessage}>
                                            <Error message={'There are no vendors added as of yet. You can add a vendor to see it here.'} />
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            <></>

            {adminTableAccess && (
                <>
                    <AdminPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={paginate}
                        itemsPerPage={itemsPerPage}
                    />
                    <DisableModal
                        open={showDisableModal}
                        onCancel={handleDisableCancel}
                        onConfirm={handleDisableConfirm}
                        isDisabled={adminToDisable ? adminToDisable.is_blocked : false}
                    />
                    <DeleteModal
                        open={showDeleteModal}
                        onCancel={cancelDelete}
                        onConfirm={confirmDelete}
                    />
                    <SnackbarNotification
                        open={snackbarOpen}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                        onClose={handleSnackbarClose}
                    />
                </>
            )}

        </>
    );
};

export default UserAdminsList;
