import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { VendorListState } from '../interfaces/interface';
import { createAnalyseBulkVendor, createBulkVendor, getVendorList, unallocateAnalyseBulkVendor, unallocateBulkVendor } from '../slices/vendorList';

const ExtraReducers = (builder: ActionReducerMapBuilder<VendorListState>) => {
  builder
  .addCase(getVendorList.pending, (state) => {
    state.getVendorStatus = STATUS.PENDING;
  })
  .addCase(getVendorList.fulfilled, (state, action) => {
    state.getVendorStatus = STATUS.SUCESS;
    state.vendor = action.payload;
  })
  .addCase(getVendorList.rejected, (state, action) => {
    state.getVendorStatus = STATUS.FAILED;
  })
  .addCase(createBulkVendor.pending, (state) => {
    state.createVendorStatus = STATUS.PENDING;
  })
  .addCase(createBulkVendor.fulfilled, (state, action: PayloadAction<any>) => {
    state.vendor = action.payload; 
    state.createVendorStatus = STATUS.SUCESS; 
  })
  .addCase(createBulkVendor.rejected, (state, action) => { 
    state.createVendorStatus = STATUS.FAILED;
  })
  .addCase(createAnalyseBulkVendor.pending, (state) => {
    state.createAnalyseStatus = STATUS.PENDING;
  })
  .addCase(createAnalyseBulkVendor.fulfilled, (state, action: PayloadAction<any>) => {
    state.analyse = action.payload; 
    state.createAnalyseStatus = STATUS.SUCESS; 
  })
  .addCase(createAnalyseBulkVendor.rejected, (state, action) => { 
    state.createAnalyseStatus = STATUS.FAILED;
  })

  .addCase(unallocateBulkVendor.pending, (state) => {
    state.unallocateBulkVendorStatus = STATUS.PENDING;
  })
  .addCase(unallocateBulkVendor.fulfilled, (state, action) => {
    state.unallocateBulkVendorStatus = STATUS.SUCESS;
  })
  .addCase(unallocateBulkVendor.rejected, (state, action) => {
    state.unallocateBulkVendorStatus = STATUS.FAILED;
  })
  
  .addCase(unallocateAnalyseBulkVendor.pending,(state, action) => {
    state.unallocateAnalyseStatus = STATUS.PENDING
  })
  .addCase(unallocateAnalyseBulkVendor.fulfilled,(state, action)=> {
    state.analyse = action.payload
    state.unallocateAnalyseStatus = STATUS.SUCESS
  })
  .addCase(unallocateAnalyseBulkVendor.rejected, (state, action) => {
    state.unallocateAnalyseStatus = STATUS.FAILED
  })

};

export default ExtraReducers;
