import React, { useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { STATUS } from "../../utils/status";
import { CircularProgress } from "@mui/material";

interface PseudoNameItems {
  task_completed_count: number;
  pseudo_name: string;
  date: string;
}

const ChartForPseudoname: React.FC<{ data: any; dataStatus: string }> = ({
  data,
  dataStatus,
}) => {
  const [loader, setLoader] = useState(false);

  
  const pseudoNameData = useMemo(() => {
    if (dataStatus === "PENDING") return [];
    

    const transformedData = data.map((item: PseudoNameItems) => ({
      name: item.pseudo_name,
      tasks: item.task_completed_count,
      date: item.date,
    }));

    return transformedData.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }, [data]);

  const colors = ["#921A40", "#F5004F", "#F5004F", "#F5004F"];

  // console.log({ pseudoNameData })

  return (
    <>
      {dataStatus === STATUS.PENDING ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <CircularProgress size={15} />
       </div>
      ) : dataStatus === STATUS.NOT_STARTED ? (
        <CircularProgress size={19} />
      ) : dataStatus === STATUS.SUCESS ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <h2
            style={{
              writingMode: "vertical-rl",
              transform: "rotate(180deg)",
              margin: 0,
              paddingRight: 10,
              wordSpacing: "10px",
              letterSpacing: "5px",
            }}
          >
            No. of tasks
          </h2>
          <ResponsiveContainer width="100%" height={550}>
            <LineChart
              data={pseudoNameData}
              margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="tasks"
                stroke={colors[3]}
                activeDot={{ r: 10 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : dataStatus === STATUS.FAILED ? (
        <span>Error!!!</span>
      ) : (
        ""
      )}
    </>
  );
};

export default ChartForPseudoname;
